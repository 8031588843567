angular.module("baseBlockExplorer", [
  "ngSanitize",
  "angularMoment",
  "cc.autorefresh",
]);

angular.module("baseBlockExplorer").constant("baseConfig", {
  apiUrl:
    window.getEnvConfig("BLOCK_EXPLORER_API_URL") ||
    "http://185.35.139.101:23457",

  baseEndPoint: "api",
  TOKEN_QUANTS: window.getEnvConfig("TOKEN_QUANTS") || 100000000,
  EPOCH: window.getEnvConfig("EPOCH") || 1484046000,
  BASE_TARGET: window.getEnvConfig("BASE_TARGET") || 17080318,
  initialSupply: window.getEnvConfig("INITIAL_SUPPLY") || 9000000000,
});

angular.module("baseBlockExplorer").constant("BASE_OPTIONS", {
  AUTO_PAGE_REFRESH_INTERVAL:
    window.getEnvConfig("AUTO_PAGE_REFRESH_INTERVAL") || 60000,
  VERSION: window.getEnvConfig("RELEASE_VERSION"),
  NETWORK_ENVIRONMENT: window.getEnvConfig("NETWORK_ENVIRONMENT"),
});

angular.module("baseBlockExplorer").filter("timestamp", [
  "$sce",
  "moment",
  "baseConfig",
  function ($sce, moment, baseConfig) {
    return function (val) {
      try {
        var inMilliseconds = (val + baseConfig.EPOCH) * 1000;

        const date = new Date(inMilliseconds);

        return `${date.toLocaleDateString("en-US", {
          timeZone: "UTC",
        })} ${date.toLocaleTimeString("en-US", {
          timeZone: "UTC",
        })} GMT`;
      } catch (e) {
        return val;
      }
    };
  },
]);

angular.module("baseBlockExplorer").filter("amountTQT", [
  "$sce",
  "baseConfig",
  function ($sce, baseConfig) {
    return function (val) {
      var amount = val / baseConfig.TOKEN_QUANTS;
      return amount.toLocaleString("en-US", { minimumFractionDigits: 8 });
    };
  },
]);

angular.module("baseBlockExplorer").filter("amountTKN", [
  "$sce",
  function ($sce) {
    return function (val) {
      val = parseInt(val);
      return val.toLocaleString("en-US", { minimumFractionDigits: 2 });
    };
  },
]);

angular.module("baseBlockExplorer").filter("supply", [
  "$sce",
  function ($sce) {
    return function (val, numOfDecimals) {
      var actualPow = numOfDecimals;
      var divider = Math.pow(10, actualPow);
      val = val / divider;
      return val.toLocaleString("en-US", { minimumFractionDigits: 2 });
    };
  },
]);

angular.module("baseBlockExplorer").filter("decimals", [
  "$sce",
  function ($sce) {
    return function (val, numOfDecimals) {
      var divider = Math.pow(10, numOfDecimals);
      val = val / divider;
      return val.toLocaleString("en-US", { minimumFractionDigits: 2 });
    };
  },
]);

angular.module("baseBlockExplorer").filter("numericalString", [
  "$sce",
  "baseConfig",
  function ($sce, baseConfig) {
    return function (val) {
      if (!val) {
        val = 0;
      }
      return val.toLocaleString("en-US", { minimumFractionDigits: 2 });
    };
  },
]);

angular.module("baseBlockExplorer").filter("numericalFormat", [
  "$sce",
  "baseConfig",
  function ($sce, baseConfig) {
    return function (val) {
      if (!val) {
        val = 0;
      }
      return val.toLocaleString("en-US", { minimumFractionDigits: 0 });
    };
  },
]);

angular.module("baseBlockExplorer").filter("trustedhtml", function ($sce) {
  return $sce.trustAsHtml;
});

angular.module("baseBlockExplorer").filter("votingModel", [
  "$sce",
  function ($sce) {
    return function (val) {
      val = parseInt(val);

      switch (val) {
        case 0:
          return "Account";
        case 1:
          return "Balance";
        case 2:
          return "Asset";
        case 3:
          return "Currency";
        default:
          return val;
      }
    };
  },
]);

angular.module("baseBlockExplorer").filter("transactionTextType", [
  "$sce",
  function ($sce) {
    return function (val) {
      switch (val) {
        case 0:
          return "Payment";
        case 1:
          return "Messaging";
        case 2:
          return "Colored Coins";
        case 3:
          return "Digital Goods";
        case 4:
          return "Account Control";
        case 5:
          return "Monetary System";
        case 6:
          return "Data";
        case 7:
          return "Shuffling";
        case 21:
          return "Advanced Transactions";
        case 22:
          return "AT";

        default:
          return val;
      }
    };
  },
]);

angular.module("baseBlockExplorer").filter("transactionTextSubType", [
  "$sce",
  function ($sce) {
    return function (type, subType) {
      switch (type) {
        case 0:
          switch (subType) {
            case 0:
              return "Ordinary Payment";
            default:
              return subType;
          }
          break;
        case 1:
          switch (subType) {
            case 0:
              return "Arbitary Message";
            case 1:
              return "Alias Assignment";
            case 2:
              return "Poll Creation";
            case 3:
              return "Vote Casting";
            case 4:
              return "Hub Announcement";
            case 5:
              return "Account Info";
            case 6:
              return "Alias Sell";
            case 7:
              return "Alias Buy";
            case 8:
              return "Alias Delete";
            case 9:
              return "Phasing Vote Casting";
            case 10:
              return "Account Property";
            case 11:
              return "Account Property delete";
            default:
              return subType;
          }
          break;
        case 2:
          switch (subType) {
            case 0:
              return "Asset Issuance";
            case 1:
              return "Asset Transfer";
            case 2:
              return "Ask Order Placement";
            case 3:
              return "Bid Order Placement";
            case 4:
              return "Ask Order Cancellation";
            case 5:
              return "Bid Order Cancellation";
            case 6:
              return "Dividend Payment";
            case 7:
              return "Asset Delete";
            default:
              return subType;
          }
          break;
        case 3:
          switch (subType) {
            case 0:
              return "Listing";
            case 1:
              return "Delisting";
            case 2:
              return "Price Change";
            case 3:
              return "Quantity Change";
            case 4:
              return "Purchase";
            case 5:
              return "Delivery";
            case 6:
              return "Feedback";
            case 7:
              return "Refund";
            default:
              return subType;
          }
          break;
        case 4:
          switch (subType) {
            case 0:
              return "Effective Balance Lease";
            case 1:
              return "Phasing Only";
            default:
              return subType;
          }
          break;

        case 5:
          switch (subType) {
            case 0:
              return "Currency Issuance";
            case 1:
              return "Reserve Increase";
            case 2:
              return "Resverve Claim";
            case 3:
              return "Currency Transfer";
            case 4:
              return "Publish Exchange Offer";
            case 5:
              return "Exchange Buy";
            case 6:
              return "Exchange Sell";
            case 7:
              return "Currency Minting";
            case 8:
              return "Currency Deletion";
            default:
              return subType;
          }
          break;

        case 7:
          switch (subType) {
            case 0:
              return "Shuffling Creation";
            case 1:
              return "Shuffling Registration";
            case 2:
              return "Shuffling Processing";
            case 3:
              return "Shuffling Recipients";
            case 4:
              return "Shuffling Verification";
            case 5:
              return "Shuffling Cancel";
            default:
              return subType;
          }
          break;

        case 21:
          switch (subType) {
            case 0:
              return "Escrow Creation";
            case 1:
              return "Escrow Sign";
            case 2:
              return "Escrow Results";
            case 3:
              return "Subscription Creation";
            case 4:
              return "Subscription Cancel";
            case 5:
              return "Subscription Payment";
            default:
              return subType;
          }
          break;

        case 22:
          switch (subType) {
            case 0:
              return "AT Creation";
            case 1:
              return "AT Payment";
            default:
              return subType;
          }
          break;
        default:
          return subType;
      }
    };
  },
]);

angular.module("baseBlockExplorer").filter("currencyModel", [
  "$sce",
  function ($sce) {
    return function (val) {
      switch (val) {
        case 1:
          return "Exchangeable";
        case 8:
          return "Claimable";
        case 16:
          return "Mintable";
        case 2:
          return "Controllable";
        case 4:
          return "Reservable";
        case 32:
          return "Non Shuffleable";
        default:
          return val;
      }
    };
  },
]);

angular.module("baseBlockExplorer").directive("dynamic", [
  "$compile",
  function ($compile) {
    return {
      restrict: "A",
      replace: true,
      link: function (scope, ele, attrs) {
        scope.$watch(attrs.dynamic, function (html) {
          ele.html(html);
          $compile(ele.contents())(scope);
        });
      },
    };
  },
]);

angular.module("baseBlockExplorer").directive("compile", [
  "$compile",
  function ($compile) {
    return function (scope, element, attrs) {
      scope.$watch(
        function (scope) {
          // watch the 'compile' expression for changes
          return scope.$eval(attrs.compile);
        },
        function (value) {
          // when the 'compile' expression changes
          // assign it into the current DOM
          element.html(value);

          // compile the new DOM and link it to the current
          // scope.
          // NOTE: we only compile .childNodes so that
          // we don't get into infinite loop compiling ourselves
          $compile(element.contents())(scope);
        }
      );
    };
  },
]);

angular.module("baseBlockExplorer").filter("isEmpty", [
  "$sce",
  function ($sce) {
    return function (val) {
      if (val === undefined || val === "") {
        return "No Data Available";
      } else {
        return val;
      }
    };
  },
]);

angular.module("baseBlockExplorer").filter("isEnabled", [
  "$sce",
  function ($sce) {
    return function (val) {
      switch (val) {
        case true:
          return '<small> <span class="glyphicon glyphicon-ok" style="color:black"></span> </small>';
        case false:
          return '<small> <span class="glyphicon glyphicon-remove" style="color:black"></span> </small>';
        default:
          return '<small> <span class="glyphicon glyphicon-remove" style="color:black"></span> </small>';
      }
    };
  },
]);

angular.module("baseBlockExplorer").filter("numericalString", [
  "$sce",
  "baseConfig",
  function ($sce, baseConfig) {
    return function (val) {
      if (!val) {
        val = 0;
      }
      return val.toLocaleString("en-US", { minimumFractionDigits: 2 });
    };
  },
]);

angular.module("baseBlockExplorer").filter("amountToQuant", [
  "$sce",
  "baseConfig",
  function ($sce, baseConfig) {
    return function (val) {
      if (!val) {
        val = 0;
      }
      var amount = parseFloat(val) * baseConfig.TOKEN_QUANTS;
      return amount;
    };
  },
]);

angular.module("baseBlockExplorer").filter("quantToAmount", [
  "$sce",
  "baseConfig",
  function ($sce, baseConfig) {
    return function (val) {
      if (!val) {
        val = 0;
      }
      var amount = parseFloat(val) / baseConfig.TOKEN_QUANTS;
      return amount.toLocaleString("en-US", { minimumFractionDigits: 8 });
    };
  },
]);

angular.module("baseBlockExplorer").filter("quantityToShare", [
  "$sce",
  function ($sce) {
    return function (val, numOfDecimals) {
      var actualPow = numOfDecimals;
      var divider = Math.pow(10, actualPow);
      val = parseFloat(val) / divider;
      return val;
    };
  },
]);

angular.module("baseBlockExplorer").filter("shareToQuantiy", [
  "$sce",
  function ($sce) {
    return function (val, numOfDecimals) {
      var actualPow = numOfDecimals;
      var multiplier = Math.pow(10, actualPow);
      val = parseFloat(val) * multiplier;
      return val;
    };
  },
]);

angular.module("baseBlockExplorer").filter("searchTerm", [
  "$sce",
  function ($sce) {
    return function (val) {
      // Filter automated transactions with numeric account id 0
      if (val && val !== "XIN-2222-2222-2222-22222") {
        return (
          '<a href="" ng-controller="SearchCtrl" ng-click="searchValue(\'' +
          val +
          "')\">" +
          val +
          "</a>"
        );
      } else {
        return "";
      }
    };
  },
]);

angular.module("baseBlockExplorer").filter("transactionIconSubType", [
  "$sce",
  function ($sce) {
    return function (type, subType) {
      switch (type) {
        case 0:
          switch (subType) {
            case 0:
              return '<i class="fa fa-usd" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Ordinary Payment"></i>';
            default:
              return subType;
          }
          break;
        case 1:
          switch (subType) {
            case 0:
              return '<i class="fa fa-envelope-o" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Encrypted Message"></i>';
            case 1:
              return '<i class="fa fa-share-alt" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Alias Assigment"></i>';
            case 2:
              return '<i class="fa fa-signal" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Poll Creation"></i>';
            case 3:
              return '<i class="fa fa-signal" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Vote Casting"></i>';
            case 4:
              return '<i class="fa fa-credit-card" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Hub Announcement"></i>';
            case 5:
              return '<i class="fa fa-credit-card" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Account Info"></i>';
            case 6:
              return '<i class="fa fa-share-alt" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Alias Sell"></i>';
            case 7:
              return '<i class="fa fa-share-alt" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Alias Buy"></i>';
            case 8:
              return '<i class="fa fa-share-alt" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Alias Delete"></i>';
            case 9:
              return '<i class="fa fa-signal" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Account Control Approval"></i>';
            case 10:
              return '<i class="fa fa-credit-card" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Account Property"></i>';
            case 11:
              return '<i class="fa fa-credit-card" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Account Property delete"></i>';
            default:
              return subType;
          }
          break;
        case 2:
          switch (subType) {
            case 0:
              return '<i class="fa fa-bar-chart" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Asset Issuance"></i>';
            case 1:
              return '<i class="fa fa-bar-chart" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Asset Transfer"></i>';
            case 2:
              return '<i class="fa fa-bar-chart" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Ask Order Placement"></i>';
            case 3:
              return '<i class="fa fa-bar-chart" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Bid Order Placement"></i>';
            case 4:
              return '<i class="fa fa-bar-chart" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Ask Order Cancellation"></i>';
            case 5:
              return '<i class="fa fa-bar-chart" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Bid Order Cancellation"></i>';
            case 6:
              return '<i class="fa fa-bar-chart" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Dividend Payment"></i>';
            case 7:
              return '<i class="fa fa-bar-chart" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Asset Delete"></i>';
            default:
              return subType;
          }
          break;

        case 4:
          switch (subType) {
            case 0:
              return '<i class="fa fa-credit-card" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Effective Balance Lease"></i>';
            case 1:
              return '<i class="fa fa-credit-card" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Account Control"></i>';
            default:
              return subType;
          }
          break;
        case 5:
          switch (subType) {
            case 0:
              return '<i class="fa fa-random" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Currency Issuance"></i>';
            case 1:
              return '<i class="fa fa-random" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Reserve Increase"></i>';
            case 2:
              return '<i class="fa fa-random" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Resverve Claim"></i>';
            case 3:
              return '<i class="fa fa-random" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Currency Transfer"></i>';
            case 4:
              return '<i class="fa fa-random" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Publish Exchange Offer"></i>';
            case 5:
              return '<i class="fa fa-random" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Exchange Buy"></i>';
            case 6:
              return '<i class="fa fa-random" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Exchange Sell"></i>';
            case 7:
              return '<i class="fa fa-random" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Currency Minting"></i>';
            case 8:
              return '<i class="fa fa-random" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Currency Deletion"></i>';
            default:
              return subType;
          }
          break;

        case 7:
          switch (subType) {
            case 0:
              return '<i class="fa fa-user-secret" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Shuffling Creation"></i>';
            case 1:
              return '<i class="fa fa-user-secret" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Shuffling Registration"></i>';
            case 2:
              return '<i class="fa fa-user-secret" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Shuffling Processing"></i>';
            case 3:
              return '<i class="fa fa-user-secret" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Shuffling Recipients"></i>';
            case 4:
              return '<i class="fa fa-user-secret" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Shuffling Verification"></i>';
            case 5:
              return '<i class="fa fa-user-secret" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Shuffling Cancel"></i>';

            default:
              return subType;
          }
          break;

        case 21:
          switch (subType) {
            case 0:
              return '<i class="fa fa-handshake-o" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Escrow Creation"></i>';
            case 1:
              return '<i class="fa fa-handshake-o" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Escrow Sign"></i>';
            case 2:
              return '<i class="fa fa-handshake-o" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Escrow Results"></i>';
            case 3:
              return '<i class="fa fa-hourglass" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Subscription Creation"></i>';
            case 4:
              return '<i class="fa fa-hourglass-o" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Subscription Cancel"></i>';
            case 5:
              return '<i class="fa fa-hourglass-half" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Subscription Payment"></i>';
            default:
              return subType;
          }
          break;

        case 22:
          switch (subType) {
            case 0:
              return '<i class="fa fa-cogs" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="AT Creation"></i>';
            case 1:
              return '<i class="fa fa-cogs" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="AT Payment"></i>';
            default:
              return subType;
          }
          break;

        default:
          return subType;
      }
    };
  },
]);

angular.module("baseBlockExplorer").filter("JSONStringify", [
  "$sce",
  function ($sce) {
    return function (data) {
      return JSON.stringify(data);
    };
  },
]);

angular.module("baseBlockExplorer").filter("transactionConf", [
  "$sce",
  function ($sce) {
    return function (value) {
      if (!value) {
        value = 0;
      }
      if (value === 0) {
        return '<span class="label label-default">' + value + "</span>";
      } else if (value > 0 && value < 10) {
        return '<span class="label label-danger">' + value + "</span>";
      } else if (value >= 10 && value < 100) {
        return '<span class="label label-warning">' + value + "</span>";
      } else if (value >= 100 && value < 720) {
        return '<span class="label label-success">' + value + "</span>";
      } else if (value >= 720) {
        return '<span class="label label-success">+720</span>';
      } else {
        return '<span class="label label-primary">' + value + "</span>";
      }
    };
  },
]);

angular.module("baseBlockExplorer").filter("isMessage", [
  "$sce",
  function ($sce) {
    return function (type, subType) {
      if (type === 1 && subType === 0) {
        return '</small> <i class="fa fa-check" aria-hidden="true"></i> </small>';
      } else {
        return '</small> <i class="fa fa-times" aria-hidden="true"></i> </small>';
      }
    };
  },
]);

angular.module("baseBlockExplorer").filter("hasMessage", [
  "$sce",
  function ($sce) {
    return function (row, account) {
      if (row.attachment.encryptedMessage) {
        if (account === row.senderRS) {
          return ' <i class="fa fa-upload" aria-hidden="true" style="color: black;"></i> ';
        } else if (account === row.recipientRS) {
          return '<i class="fa fa-download" aria-hidden="true" style="color:black;"></i>';
        } else {
          return '</small> <i class="fa fa-check" aria-hidden="true"></i> </small>';
        }
      } else {
        return '</small> <i class="fa fa-times" aria-hidden="true"></i> </small>';
      }
    };
  },
]);

angular.module("baseBlockExplorer").filter("buysell", [
  "$sce",
  function ($sce) {
    return function (val) {
      switch (val) {
        case "buy":
          return '<span class="label label-success">B</span>';
        case "sell":
          return '<span class="label label-danger">S</span>';
        default:
          return '<span class="label label-default">U</span>';
      }
    };
  },
]);

angular.module("baseBlockExplorer").filter("transactionTextSubType2", [
  "$sce",
  function ($sce) {
    return function (type, subType) {
      switch (type) {
        case 0:
          switch (subType) {
            case 0:
              return "Ordinary Payment";
            default:
              return subType;
          }
          break;
        case 1:
          switch (subType) {
            case 0:
              return "Arbitary Message";
            case 1:
              return "Alias Assignment";
            case 2:
              return "Poll Creation";
            case 3:
              return "Vote Casting";
            case 4:
              return "Hub Announcement";
            case 5:
              return "Account Info";
            case 6:
              return "Alias Sell";
            case 7:
              return "Alias Buy";
            case 8:
              return "Alias Delete";
            case 9:
              return "Phasing Vote Casting";
            case 10:
              return "Account Property";
            case 11:
              return "Account Property delete";
            default:
              return subType;
          }
          break;
        case 2:
          switch (subType) {
            case 0:
              return "Asset Issuance";
            case 1:
              return "Asset Transfer";
            case 2:
              return "Ask Order Placement";
            case 3:
              return "Bid Order Placement";
            case 4:
              return "Ask Order Cancellation";
            case 5:
              return "Bid Order Cancellation";
            case 6:
              return "Dividend Payment";
            case 7:
              return "Asset Delete";
            default:
              return subType;
          }
          break;

        case 4:
          switch (subType) {
            case 0:
              return "Effective Balance Lease";
            case 1:
              return "Phasing Only";
            default:
              return subType;
          }
          break;
        case 5:
          switch (subType) {
            case 0:
              return "Currency Issuance";
            case 1:
              return "Reserve Increase";
            case 2:
              return "Resverve Claim";
            case 3:
              return "Currency Transfer";
            case 4:
              return "Publish Exchange Offer";
            case 5:
              return "Exchange Buy";
            case 6:
              return "Exchange Sell";
            case 7:
              return "Currency Minting";
            case 8:
              return "Currency Deletion";
            default:
              return subType;
          }
          break;

        case 7:
          switch (subType) {
            case 0:
              return "Shuffling Creation";
            case 1:
              return "Shuffling Registration";
            case 2:
              return "Shuffling Processing";
            case 3:
              return "Shuffling Recipients";
            case 4:
              return "Shuffling Verification";
            case 5:
              return "Shuffling Cancel";
            default:
              return subType;
          }
          break;

        case 21:
          switch (subType) {
            case 0:
              return "Escrow Creation";
            case 1:
              return "Escrow Sign";
            case 2:
              return "Escrow Results";
            case 3:
              return "Subscription Creation";
            case 4:
              return "Subscription Cancel";
            case 5:
              return "Subscription Payment";
            default:
              return subType;
          }
          break;

        case 22:
          switch (subType) {
            case 0:
              return "AT Creation";
            case 1:
              return "AT Payment";
            default:
              return subType;
          }
          break;

        default:
          return subType;
      }
    };
  },
]);

angular.module("baseBlockExplorer").filter("numberString", [
  "$sce",
  function ($sce) {
    return function (val, numOfDecimals) {
      return val.toLocaleString("en-US", {
        maximumFractionDigits: numOfDecimals,
      });
    };
  },
]);

angular.module("baseBlockExplorer").filter("votingModelLabel", [
  "$sce",
  function ($sce) {
    return function (val) {
      val = parseInt(val);

      switch (val) {
        case 0:
          return '<i class="fa fa-user-o" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Account"></i>'; // Account
        case 1:
          return '<i class="fa fa-credit-card" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Balance"></i>'; // 'Balance';
        case 2:
          return '<i class="fa fa-bar-chart" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Asset"></i>'; // 'Asset';
        case 3:
          return '<i class="fa fa-random" aria-hidden="true" popover-placement="top" popover-trigger="\'mouseenter\'" uib-popover="Currency"></i>'; //'Currency';
        default:
          return val;
      }
    };
  },
]);

angular.module("blocks", [
  "baseBlockExplorer",
  "restangular",
  "datatables",
  "datatables.bootstrap",
  "ui.bootstrap",
  "ui.router",
]);

angular.module("blocks").constant("blocksConfig", {
  blocksEndPoint: "api",
});

angular.module("blocks").config([
  "RestangularProvider",
  "blocksConfig",
  "$stateProvider",
  "$urlRouterProvider",
  "baseConfig",
  function (
    RestangularProvider,
    blocksConfig,
    $stateProvider,
    $urlRouterProvider,
    baseConfig
  ) {
    RestangularProvider.setBaseUrl(baseConfig.apiUrl);

    $stateProvider.state("blockExplorer.blocks", {
      url: "^/blocks",
      templateUrl: "./blocks/blocks.html",
      controller: "BlocksCtrl",
    });
  },
]);

angular.module("blocks").service("BlocksService", [
  "Restangular",
  "blocksConfig",
  function (Restangular, blocksConfig) {
    this.getBlocks = function (firstIndex, lastIndex) {
      var params = {
        requestType: "getBlocks",
        firstIndex: firstIndex,
        lastIndex: lastIndex,
      };
      return Restangular.all(blocksConfig.blocksEndPoint).customGET("", params);
    };

    this.getBlockDetails = function (height, includeTransactions) {
      var params = {
        requestType: "getBlock",
        height: height,
        includeTransactions: includeTransactions,
      };
      return Restangular.all(blocksConfig.blocksEndPoint).customGET("", params);
    };

    this.getBlockChainStatus = function () {
      var params = {
        requestType: "getBlockchainStatus",
      };
      return Restangular.all(blocksConfig.blocksEndPoint).customGET("", params);
    };
  },
]);

angular.module("blocks").controller("BlockCtrl", [
  "$scope",
  "BlocksService",
  "timestampFilter",
  "amountTQTFilter",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "$compile",
  "$uibModalInstance",
  "params",
  "Restangular",
  "$uibModal",
  "searchTermFilter",
  "quantityToShareFilter",
  "numericalStringFilter",
  "quantToAmountFilter",
  "shareToQuantiyFilter",
  "transactionIconSubTypeFilter",
  function (
    $scope,
    BlocksService,
    timestampFilter,
    amountTQTFilter,
    DTOptionsBuilder,
    DTColumnBuilder,
    $compile,
    $uibModalInstance,
    params,
    Restangular,
    $uibModal,
    searchTermFilter,
    quantityToShareFilter,
    numericalStringFilter,
    quantToAmountFilter,
    shareToQuantiyFilter,
    transactionIconSubTypeFilter
  ) {
    $scope.height = params.height;
    $scope.includeTransactions = params.includeTransactions;

    $scope.cancel = function () {
      $uibModalInstance.dismiss("cancel");
    };

    $scope.transactions = [];

    $scope.dtOptions = DTOptionsBuilder.newOptions()
      .withPaginationType("numbers")
      .withOption("serverSide", true)
      .withDataProp("data")
      .withOption("processing", true)
      .withOption("paging", false)
      .withOption("ordering", false)
      .withOption("info", false)
      .withOption("bFilter", false)
      .withOption(
        "fnRowCallback",
        function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          $compile(nRow)($scope);
        }
      )
      .withOption("ajax", function (data, callback, settings) {
        BlocksService.getBlockDetails(
          $scope.height,
          $scope.includeTransactions
        ).then(function (response) {
          var convertedResponse = { data: [response] };
          callback({
            iTotalRecords: 1,
            iTotalDisplayRecords: 1,
            data: convertedResponse.data,
          });
        });
      })
      .withDisplayLength(1)
      .withBootstrap();

    $scope.dtColumns = [
      DTColumnBuilder.newColumn("height")
        .withTitle("Height")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return (
            '<button type="button" class="btn btn-infinity btn-xs" ng-click="openDetailsModal(' +
            data +
            ')">' +
            data +
            "</button>"
          );
        }),

      DTColumnBuilder.newColumn("block").withTitle("Block Id").notSortable(),
      DTColumnBuilder.newColumn("numberOfTransactions")
        .withTitle("Transactions")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return getBlocksTxs(data);
        }),
      DTColumnBuilder.newColumn("totalAmountTQT")
        .withTitle("Amount")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return numericalStringFilter(quantToAmountFilter(data));
        }),
      DTColumnBuilder.newColumn("totalFeeTQT")
        .withTitle("Fee")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return numericalStringFilter(quantToAmountFilter(data));
        }),
      DTColumnBuilder.newColumn("generatorRS")
        .withTitle("Generator")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return searchTermFilter(data);
        }),
      DTColumnBuilder.newColumn("payloadLength")
        .withTitle("Payload")
        .notSortable(),
      DTColumnBuilder.newColumn("timestamp")
        .withTitle("Date")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return timestampFilter(data);
        }),

      DTColumnBuilder.newColumn("previousBlock")
        .withTitle("Previous Block")
        .withOption("defaultContent", " ")
        .notSortable(),
    ];

    $scope.dtOptionsTransactions = DTOptionsBuilder.newOptions()
      .withDOM("frtip")
      .withPaginationType("numbers")
      .withOption("serverSide", false)
      .withDataProp("data")
      .withOption("processing", true)
      .withOption("paging", true)
      .withOption("ordering", false)
      .withOption("info", false)
      .withOption("bFilter", false)
      .withOption(
        "fnRowCallback",
        function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          $compile(nRow)($scope);
        }
      )
      .withOption("ajax", function (data, callback, settings) {
        BlocksService.getBlockDetails(
          $scope.height,
          $scope.includeTransactions
        ).then(function (response) {
          callback({
            iTotalRecords: response.transactions.length,
            iTotalDisplayRecords: response.transactions.length,
            data: response.transactions,
          });
        });
      })
      .withDisplayLength(5)
      .withBootstrap();

    $scope.dtColumnsTransactions = [
      DTColumnBuilder.newColumn("transaction")
        .withTitle("Details")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          var details =
            '<a type="button" class="btn btn-infinity btn-xs" ng-controller="SearchCtrl"' +
            " ng-click=\"searchValue('" +
            data +
            "')\">" +
            '<i class="fa fa-list-ul" aria-hidden="true"></i>' +
            "</a>";
          return details;
        }),

      DTColumnBuilder.newColumn("type")
        .withTitle("Type")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return transactionIconSubTypeFilter(data, row.subtype);
        }),

      DTColumnBuilder.newColumn("timestamp")
        .withTitle("Timestamp")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return timestampFilter(data);
        }),
      DTColumnBuilder.newColumn("amountTQT")
        .withTitle("Amount")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return numericalStringFilter(quantToAmountFilter(data));
        }),
      DTColumnBuilder.newColumn("feeTQT")
        .withTitle("Fee")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return numericalStringFilter(quantToAmountFilter(data));
        }),
      DTColumnBuilder.newColumn("confirmations")
        .withTitle("Conf.")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return getTransactionConf(data);
        }),
      DTColumnBuilder.newColumn("senderRS")
        .withTitle("Sender")
        .notSortable()
        .withOption("defaultContent", " ")
        .renderWith(function (data, type, row, meta) {
          if (data) {
            return searchTermFilter(data);
          }
          return data;
        }),
      DTColumnBuilder.newColumn("recipientRS")
        .withTitle("Recipient")
        .notSortable()
        .withOption("defaultContent", " ")
        .renderWith(function (data, type, row, meta) {
          if (data) {
            return searchTermFilter(data);
          }
          return data;
        }),
    ];

    $scope.dtInstanceCallbackTransactions = {};

    function getBlocksTxs(value) {
      if (value === 0) {
        return '<span class="label label-default">' + value + "</span>";
      } else if (value > 0 && value < 100) {
        return '<span class="label label-success">' + value + "</span>";
      } else if (value >= 100 && value < 200) {
        return '<span class="label label-warning">' + value + "</span>";
      } else if (value >= 200) {
        return '<span class="label label-danger">' + value + "</span>";
      }
    }

    function getTransactionConf(value) {
      if (value === 0) {
        return '<span class="label label-default">' + value + "</span>";
      } else if (value > 0 && value < 10) {
        return '<span class="label label-danger">' + value + "</span>";
      } else if (value >= 10 && value < 100) {
        return '<span class="label label-warning">' + value + "</span>";
      } else if (value >= 100 && value < 720) {
        return '<span class="label label-success">' + value + "</span>";
      } else {
        return '<span class="label label-success">+720</span>';
      }
    }

    function getTransactionTypeGlyphicon(value) {
      switch (value) {
        case 0:
          return "glyphicon-usd";
        case 1:
          return "glyphicon-envelope";
        case 2:
          return "glyphicon-signal";
        case 3:
          return "glyphicon-shopping-cart";
        case 4:
          return "glyphicon-info-sign";
        case 5:
          return "glyphicon-random";
        case 6:
          return "glyphicon-save";
        case 7:
          return "glyphicon-link";
      }
    }

    $scope.showBlockDetails = function () {
      BlocksService.getBlockDetails(params.blockDetails, false).then(function (
        response
      ) {
        $scope.blockDetails = Restangular.stripRestangular(response);
      });
    };

    $scope.convertCamelToRegular = function (text) {
      var result = text.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
    };

    $scope.openDetailsModal = function (data) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: "blocks/block-details.html",
        size: "lg",
        controller: "BlockCtrl",
        windowClass: "block-modal-window",
        resolve: {
          params: function () {
            return {
              blockDetails: data,
            };
          },
        },
      });
    };

    $scope.generateSearchLink = function (searchTerm) {
      var accountHtml =
        '<a href="" ng-controller="SearchCtrl" ng-click="searchValue(\'' +
        searchTerm +
        "')\">" +
        searchTerm +
        "</a>";
      return accountHtml;
    };

    $scope.generateNumberOfTxsHtml = function (number) {
      return getBlocksTxs(number);
    };
  },
]);

angular.module("blocks").controller("BlocksCtrl", [
  "$scope",
  "BlocksService",
  "baseConfig",
  "timestampFilter",
  "amountTQTFilter",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "$interval",
  "$uibModal",
  "$compile",
  "searchTermFilter",
  "quantityToShareFilter",
  "numericalStringFilter",
  "quantToAmountFilter",
  "shareToQuantiyFilter",
  function (
    $scope,
    BlocksService,
    baseConfig,
    timestampFilter,
    amountTQTFilter,
    DTOptionsBuilder,
    DTColumnBuilder,
    $interval,
    $uibModal,
    $compile,
    searchTermFilter,
    quantityToShareFilter,
    numericalStringFilter,
    quantToAmountFilter,
    shareToQuantiyFilter
  ) {
    $scope.dtOptions = DTOptionsBuilder.newOptions()
      .withPaginationType("numbers")
      .withDOM("frtip")
      .withOption("responsive", true)
      .withOption("ordering", false)
      .withOption("info", false)
      .withOption("serverSide", true)
      .withDataProp("blocks")
      .withOption("paging", true)
      .withOption("processing", true)
      .withOption("bFilter", false)
      .withOption(
        "fnRowCallback",
        function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          $compile(nRow)($scope);
        }
      )
      .withOption("ajax", function (data, callback, settings) {
        var endIndex = data.start + data.length - 1;
        BlocksService.getBlocks(data.start, endIndex).then(function (response) {
          callback({
            iTotalRecords: 1000,
            iTotalDisplayRecords: 1000,
            blocks: response.blocks,
          });
        });
      })
      .withDisplayLength(10)
      .withBootstrap();

    $scope.dtColumns = [
      DTColumnBuilder.newColumn("height")
        .withTitle("Height")
        .withOption("bSortable", false)
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return (
            '<button type="button" class="btn btn-infinity btn-xs" ng-click="openModal(' +
            data +
            ')">' +
            data +
            "</button>"
          );
        }),

      DTColumnBuilder.newColumn("block").withTitle("Id").notSortable(),

      DTColumnBuilder.newColumn("numberOfTransactions")
        .withTitle("Txs")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return getBlocksTxs(data);
        }),
      DTColumnBuilder.newColumn("totalAmountTQT")
        .withTitle("Amount")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return numericalStringFilter(quantToAmountFilter(data));
        }),
      DTColumnBuilder.newColumn("totalFeeTQT")
        .withTitle("Fee")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return numericalStringFilter(quantToAmountFilter(data));
        }),
      DTColumnBuilder.newColumn("generatorRS")
        .withTitle("Generator")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return searchTermFilter(data);
        }),
      DTColumnBuilder.newColumn("timestamp")
        .withTitle("Date")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return $scope.formatDate(data);
        }),
    ];

    $scope.openModal = function (height) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: "blocks/block.html",
        size: "lg",
        controller: "BlockCtrl",
        windowClass: "block-modal-window",
        resolve: {
          params: function () {
            return {
              height: height,
              includeTransactions: true,
            };
          },
        },
      });
    };

    function baseTarget(value) {
      var target = (value / baseConfig.BASE_TARGET) * 100;

      if (target < 1000) {
        return (
          '<span class="label label-success">' +
          target.toFixed(2) +
          " %" +
          "</span>"
        );
      } else if (target >= 1000 && target < 2500) {
        return (
          '<span class="label label-warning">' +
          target.toFixed(2) +
          " %" +
          "</span>"
        );
      } else if (target >= 2500) {
        return (
          '<span class="label label-danger">' +
          target.toFixed(2) +
          " %" +
          "</span>"
        );
      } else {
        return (
          '<span class="label label-default">' +
          target.toFixed(2) +
          " %" +
          "</span>"
        );
      }
    }

    function getBlocksTxs(value) {
      if (value === 0) {
        return '<span class="label label-default">' + value + "</span>";
      } else if (value > 0 && value < 100) {
        return '<span class="label label-success">' + value + "</span>";
      } else if (value >= 100 && value < 200) {
        return '<span class="label label-warning">' + value + "</span>";
      } else if (value >= 200) {
        return '<span class="label label-danger">' + value + "</span>";
      }
    }

    $scope.dtInstanceCallback = function (_dtInstance) {
      $scope.dtInstance = _dtInstance;
    };

    $scope.reloadBlocks = function () {
      if ($scope.dtInstance) {
        $scope.dtInstance._renderer.rerender();
      }
    };
  },
]);

angular.module("assets", [
  "restangular",
  "datatables",
  "datatables.bootstrap",
  "ui.bootstrap",
  "ui.router",
]);

angular.module("assets").constant("assetsConfig", {
  assetsEndPoint: "api",
});

angular.module("assets").config([
  "RestangularProvider",
  "assetsConfig",
  "$stateProvider",
  "$urlRouterProvider",
  "baseConfig",
  function (
    RestangularProvider,
    assetsConfig,
    $stateProvider,
    $urlRouterProvider,
    baseConfig
  ) {
    RestangularProvider.setBaseUrl(baseConfig.apiUrl);

    $stateProvider.state("blockExplorer.assets", {
      url: "^/assets",
      templateUrl: "./assets/assets.html",
      controller: "AssetsCtrl",
    });
  },
]);

angular.module("assets").service("AssetsService", [
  "Restangular",
  "assetsConfig",
  "baseConfig",
  function (Restangular, assetsConfig, baseConfig) {
    this.getAssets = function (firstIndex, lastIndex) {
      var params = {
        requestType: "getAllAssets",
        firstIndex: firstIndex,
        lastIndex: lastIndex,
        includeCounts: true,
      };
      return Restangular.all(assetsConfig.assetsEndPoint).customGET("", params);
    };

    this.getAsset = function (asset, includeCounts) {
      params = {
        requestType: "getAsset",
        asset: asset,
        includeCounts: true,
        includeAssetInfo: true,
      };
      return Restangular.all(assetsConfig.assetsEndPoint).customGET("", params);
    };

    this.getTrades = function (asset, firstIndex, lastIndex) {
      var params = {
        requestType: "getTrades",
        asset: asset,
        firstIndex: firstIndex,
        lastIndex: lastIndex,
        includeAssetInfo: true,
      };
      return Restangular.all(assetsConfig.assetsEndPoint).customGET("", params);
    };

    this.getTransfers = function (asset, firstIndex, lastIndex) {
      var params = {
        requestType: "getAssetTransfers",
        asset: asset,
        firstIndex: firstIndex,
        lastIndex: lastIndex,
        includeAssetInfo: true,
      };
      return Restangular.all(assetsConfig.assetsEndPoint).customGET("", params);
    };
  },
]);

angular.module("assets").controller("AssetsCtrl", [
  "$scope",
  "AssetsService",
  "amountTQTFilter",
  "timestampFilter",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "$interval",
  "$uibModal",
  "$compile",
  "baseConfig",
  "supplyFilter",
  "searchTermFilter",
  "quantityToShareFilter",
  "numericalStringFilter",
  "quantToAmountFilter",
  function (
    $scope,
    AssetsService,
    amountTQTFilter,
    timestampFilter,
    DTOptionsBuilder,
    DTColumnBuilder,
    $interval,
    $uibModal,
    $compile,
    baseConfig,
    supplyFilter,
    searchTermFilter,
    quantityToShareFilter,
    numericalStringFilter,
    quantToAmountFilter
  ) {
    $scope.dtOptions = DTOptionsBuilder.newOptions()
      .withPaginationType("numbers")
      .withDOM("frtip")
      .withOption("paging", true)
      .withOption("ordering", false)
      .withOption("info", false)
      .withOption("serverSide", true)
      .withDataProp("assets")
      .withOption("processing", true)
      .withOption("bFilter", false)
      .withOption(
        "fnRowCallback",
        function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          $compile(nRow)($scope);
        }
      )
      .withOption("ajax", function (data, callback, settings) {
        var endIndex = data.start + data.length - 1;
        AssetsService.getAssets(data.start, endIndex).then(function (response) {
          callback({
            iTotalRecords: 1000,
            iTotalDisplayRecords: 1000,
            assets: response.assets,
          });
        });
      })
      .withDisplayLength(10)
      .withBootstrap();

    $scope.dtColumns = [
      DTColumnBuilder.newColumn("asset")
        .withTitle("Details")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          var tt_info =
            ' popover-placement="bottom" popover-trigger="\'mouseenter\'" uib-popover=' +
            ' "Assets Issuance details"';

          return (
            '<button type="button" class="btn btn-infinity btn-xs"  ' +
            tt_info +
            ' ng-controller="SearchCtrl"' +
            " ng-click=\"searchValue('" +
            data +
            "')\">" +
            '<i class="fa fa-list-ul" aria-hidden="true"></i>' +
            "</button>"
          );
        }),

      DTColumnBuilder.newColumn("name")
        .withTitle("Name")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return (
            '<a type="button" class="pointer"  ng-click="openModal(\'' +
            row.asset +
            "')\">" +
            data.toUpperCase() +
            "</a>"
          );
        }),
      DTColumnBuilder.newColumn("quantityQNT")
        .withTitle("Supply")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return numericalStringFilter(
            quantityToShareFilter(data, row.decimals)
          );
        }),
      DTColumnBuilder.newColumn("numberOfAccounts")
        .withTitle("Shareholders")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return data;
        }),
      DTColumnBuilder.newColumn("numberOfTrades")
        .withTitle("Trades")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return data;
        }),
      DTColumnBuilder.newColumn("numberOfTransfers")
        .withTitle("Transfers")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return data;
        }),
      DTColumnBuilder.newColumn("accountRS")
        .withTitle("Issuer")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return searchTermFilter(data);
        }),
      DTColumnBuilder.newColumn("asset")
        .withTitle("View")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          var tt_trades =
            ' popover-placement="bottom" popover-trigger="\'mouseenter\'" uib-popover=' +
            ' "Trades"';

          var tt_transfer =
            ' popover-placement="bottom" popover-trigger="\'mouseenter\'" uib-popover=' +
            ' "Transfers"';

          var trades =
            '<button type="button" class="btn btn-default btn-xs" ' +
            tt_trades +
            " ng-click=\"openAssetTrades('" +
            data +
            "')\">" +
            '<i class="fa fa-bar-chart" aria-hidden="true"></i>' +
            "</button>";

          var transfers =
            '<button type="button" class="btn btn-default btn-xs ' +
            tt_transfer +
            ' " ng-click="openAssetTransfers(\'' +
            data +
            "')\">" +
            '<i class="fa fa-user" aria-hidden="true" style="width:15px;"></i>' +
            "</button>";

          return trades + "&nbsp;" + transfers;
        }),
    ];

    $scope.openModal = function (asset) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: "assets/asset.html",
        size: "lg",
        controller: "AssetCtrl",
        windowClass: "asset-modal-window",
        resolve: {
          params: function () {
            return {
              asset: asset,
              includeCounts: true,
            };
          },
        },
      });
    };

    $scope.openAssetTrades = function (assetId) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: "assets/asset-trades.html",
        size: "lg",
        controller: "AssetTradeCtrl",
        windowClass: "asset-modal-window",
        resolve: {
          params: function () {
            return {
              assetId: assetId,
            };
          },
        },
      });
    };

    $scope.openAssetTransfers = function (assetId) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: "assets/asset-transfers.html",
        size: "lg",
        controller: "AssetTransferCtrl",
        windowClass: "asset-modal-window",
        resolve: {
          params: function () {
            return {
              assetId: assetId,
            };
          },
        },
      });
    };

    $scope.dtInstanceCallback = function (_dtInstance) {
      $scope.dtInstance = _dtInstance;
    };

    $scope.reloadAssets = function () {
      if ($scope.dtInstance) {
        $scope.dtInstance._renderer.rerender();
      }
    };
  },
]);

angular.module("assets").controller("AssetCtrl", [
  "$scope",
  "AssetsService",
  "amountTQTFilter",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "$compile",
  "$uibModalInstance",
  "params",
  "supplyFilter",
  function (
    $scope,
    AssetsService,
    amountTQTFilter,
    DTOptionsBuilder,
    DTColumnBuilder,
    $compile,
    $uibModalInstance,
    params,
    supplyFilter
  ) {
    $scope.asset = params.asset;
    $scope.includeCounts = params.includeCounts;

    $scope.cancel = function () {
      $uibModalInstance.dismiss("cancel");
    };

    $scope.loadDetails = function () {
      AssetsService.getAsset($scope.asset, $scope.includeCounts).then(function (
        response
      ) {
        $scope.assetDetails = response;
      });
    };

    $scope.generateSearchLink = function (searchTerm) {
      var accountHtml =
        '<a href="" ng-controller="SearchCtrl" ng-click="searchValue(\'' +
        searchTerm +
        "')\">" +
        searchTerm +
        "</a>";
      return accountHtml;
    };
  },
]);

angular.module("assets").controller("AssetTradeCtrl", [
  "$scope",
  "AssetsService",
  "amountTQTFilter",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "$compile",
  "$uibModalInstance",
  "params",
  "amountTKNFilter",
  "timestampFilter",
  "supplyFilter",
  "searchTermFilter",
  "quantityToShareFilter",
  "numericalStringFilter",
  "quantToAmountFilter",
  "shareToQuantiyFilter",
  "buysellFilter",
  function (
    $scope,
    AssetsService,
    amountTQTFilter,
    DTOptionsBuilder,
    DTColumnBuilder,
    $compile,
    $uibModalInstance,
    params,
    amountTKNFilter,
    timestampFilter,
    supplyFilter,
    searchTermFilter,
    quantityToShareFilter,
    numericalStringFilter,
    quantToAmountFilter,
    shareToQuantiyFilter,
    buysellFilter
  ) {
    $scope.assetId = params.assetId;

    $scope.dtOptions = DTOptionsBuilder.newOptions()
      .withPaginationType("numbers")
      .withDOM("frtip")
      .withOption("paging", true)
      .withOption("ordering", false)
      .withOption("info", false)
      .withOption("serverSide", true)
      .withDataProp("trades")
      .withOption("processing", true)
      .withOption("bFilter", false)
      .withOption(
        "fnRowCallback",
        function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          $compile(nRow)($scope);
        }
      )
      .withOption("ajax", function (data, callback, settings) {
        var endIndex = data.start + data.length - 1;
        AssetsService.getTrades($scope.assetId, data.start, endIndex).then(
          function (response) {
            callback({
              iTotalRecords: 1000,
              iTotalDisplayRecords: 1000,
              trades: response.trades,
            });
          }
        );
      })
      .withDisplayLength(10)
      .withBootstrap();

    $scope.dtColumns = [
      DTColumnBuilder.newColumn("timestamp")
        .withTitle("Date")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return $scope.formatDate(data);
        }),

      DTColumnBuilder.newColumn("priceTQT")
        .withTitle("Price")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return numericalStringFilter(
            quantToAmountFilter(shareToQuantiyFilter(data, row.decimals))
          );
        }),

      DTColumnBuilder.newColumn("quantityQNT")
        .withTitle("Quantity")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return numericalStringFilter(
            quantityToShareFilter(data, row.decimals)
          );
        }),

      DTColumnBuilder.newColumn("askOrder")
        .withTitle("Ask")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          var tt_info =
            ' popover-placement="bottom" popover-trigger="\'mouseenter\'" uib-popover=' +
            ' "Ask Order Details"';

          return (
            '<button type="button" class="btn btn-infinity btn-xs"  ' +
            tt_info +
            ' ng-controller="SearchCtrl"' +
            " ng-click=\"searchValue('" +
            data +
            "')\">" +
            '<i class="fa fa-list-ul" aria-hidden="true"></i>' +
            "</button>"
          );
        }),

      DTColumnBuilder.newColumn("bidOrder")
        .withTitle("Bid")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          var tt_info =
            ' popover-placement="bottom" popover-trigger="\'mouseenter\'" uib-popover=' +
            ' "Bid Order Details"';

          return (
            '<button type="button" class="btn btn-infinity btn-xs"  ' +
            tt_info +
            ' ng-controller="SearchCtrl"' +
            " ng-click=\"searchValue('" +
            data +
            "')\">" +
            '<i class="fa fa-list-ul" aria-hidden="true"></i>' +
            "</button>"
          );
        }),

      DTColumnBuilder.newColumn("sellerRS")
        .withTitle("Seller")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return searchTermFilter(data);
        }),

      DTColumnBuilder.newColumn("buyerRS")
        .withTitle("Buyer")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return searchTermFilter(data);
        }),

      DTColumnBuilder.newColumn("tradeType")
        .withTitle("Type")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return buysellFilter(data);
        }),
    ];

    $scope.cancel = function () {
      $uibModalInstance.dismiss("cancel");
    };
  },
]);

angular.module("assets").controller("AssetTransferCtrl", [
  "$scope",
  "AssetsService",
  "amountTQTFilter",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "$compile",
  "$uibModalInstance",
  "params",
  "supplyFilter",
  "amountTKNFilter",
  "timestampFilter",
  "searchTermFilter",
  "quantityToShareFilter",
  "numericalStringFilter",
  "quantToAmountFilter",
  "shareToQuantiyFilter",
  function (
    $scope,
    AssetsService,
    amountTQTFilter,
    DTOptionsBuilder,
    DTColumnBuilder,
    $compile,
    $uibModalInstance,
    params,
    supplyFilter,
    amountTKNFilter,
    timestampFilter,
    searchTermFilter,
    quantityToShareFilter,
    numericalStringFilter,
    quantToAmountFilter,
    shareToQuantiyFilter
  ) {
    $scope.assetId = params.assetId;

    $scope.dtOptions = DTOptionsBuilder.newOptions()
      .withPaginationType("numbers")
      .withDOM("frtip")
      .withOption("paging", true)
      .withOption("ordering", false)
      .withOption("info", false)
      .withOption("serverSide", true)
      .withDataProp("transfers")
      .withOption("processing", true)
      .withOption("bFilter", false)
      .withOption(
        "fnRowCallback",
        function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          $compile(nRow)($scope);
        }
      )
      .withOption("ajax", function (data, callback, settings) {
        var endIndex = data.start + data.length - 1;
        AssetsService.getTransfers($scope.assetId, data.start, endIndex).then(
          function (response) {
            callback({
              iTotalRecords: 1000,
              iTotalDisplayRecords: 1000,
              transfers: response.transfers,
            });
          }
        );
      })
      .withDisplayLength(10)
      .withBootstrap();

    $scope.dtColumns = [
      DTColumnBuilder.newColumn("assetTransfer")
        .withTitle("Details")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          var tt_info =
            ' popover-placement="bottom" popover-trigger="\'mouseenter\'" uib-popover=' +
            ' "Transfer Details"';

          return (
            '<button type="button" class="btn btn-infinity btn-xs"  ' +
            tt_info +
            ' ng-controller="SearchCtrl"' +
            " ng-click=\"searchValue('" +
            data +
            "')\">" +
            '<i class="fa fa-list-ul" aria-hidden="true"></i>' +
            "</button>"
          );
        }),

      DTColumnBuilder.newColumn("timestamp")
        .withTitle("Date")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return timestampFilter(data);
        }),

      DTColumnBuilder.newColumn("quantityQNT")
        .withTitle("Quantity")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return numericalStringFilter(
            quantityToShareFilter(data, row.decimals)
          );
        }),
      DTColumnBuilder.newColumn("senderRS")
        .withTitle("Sender")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return searchTermFilter(data);
        }),

      DTColumnBuilder.newColumn("recipientRS")
        .withTitle("Recipient")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return searchTermFilter(data);
        }),
    ];

    $scope.cancel = function () {
      $uibModalInstance.dismiss("cancel");
    };
  },
]);

angular.module("currency", [
  "restangular",
  "datatables",
  "datatables.bootstrap",
  "ui.bootstrap",
  "ui.router",
  "search",
]);

angular.module("currency").constant("currencyConfig", {
  currencyEndPoint: "api",
});

angular.module("currency").config([
  "RestangularProvider",
  "currencyConfig",
  "$stateProvider",
  "$urlRouterProvider",
  "baseConfig",
  function (
    RestangularProvider,
    currencyConfig,
    $stateProvider,
    $urlRouterProvider,
    baseConfig
  ) {
    RestangularProvider.setBaseUrl(baseConfig.apiUrl);

    $stateProvider.state("blockExplorer.currencies", {
      url: "^/currencies",
      templateUrl: "./currencies/currencies.html",
      controller: "CurrenciesCtrl",
    });
  },
]);

angular.module("currency").service("CurrencyService", [
  "Restangular",
  "currencyConfig",
  function (Restangular, currencyConfig) {
    this.getCurrencies = function (firstIndex, lastIndex) {
      var params = {
        requestType: "getAllCurrencies",
        firstIndex: firstIndex,
        lastIndex: lastIndex,
        includeCounts: true,
      };
      return Restangular.all(currencyConfig.currencyEndPoint).customGET(
        "",
        params
      );
    };

    this.getCurrency = function (code, includeCounts) {
      params = {
        requestType: "getCurrency",
        code: code,
        includeCounts: includeCounts,
        // 'includeCounts': true
      };
      return Restangular.all(currencyConfig.currencyEndPoint).customGET(
        "",
        params
      );
    };

    this.getExchanges = function (currency, firstIndex, lastIndex) {
      var params = {
        requestType: "getExchanges",
        currency: currency,
        firstIndex: firstIndex,
        lastIndex: lastIndex,
      };
      return Restangular.all(currencyConfig.currencyEndPoint).customGET(
        "",
        params
      );
    };

    this.getTransfers = function (currency, firstIndex, lastIndex) {
      var params = {
        requestType: "getCurrencyTransfers",
        currency: currency,
        firstIndex: firstIndex,
        lastIndex: lastIndex,
      };
      return Restangular.all(currencyConfig.currencyEndPoint).customGET(
        "",
        params
      );
    };
  },
]);

angular.module("currency").controller("CurrenciesCtrl", [
  "$scope",
  "CurrencyService",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "$interval",
  "$uibModal",
  "$compile",
  "baseConfig",
  "amountTQTFilter",
  "amountTKNFilter",
  "supplyFilter",
  "searchTermFilter",
  "quantityToShareFilter",
  "numericalStringFilter",
  "quantToAmountFilter",
  "shareToQuantiyFilter",
  function (
    $scope,
    CurrencyService,
    DTOptionsBuilder,
    DTColumnBuilder,
    $interval,
    $uibModal,
    $compile,
    baseConfig,
    amountTQTFilter,
    amountTKNFilter,
    supplyFilter,
    searchTermFilter,
    quantityToShareFilter,
    numericalStringFilter,
    quantToAmountFilter,
    shareToQuantiyFilter
  ) {
    $scope.dtOptions = DTOptionsBuilder.newOptions()
      .withPaginationType("numbers")
      .withDOM("frtip")
      .withOption("paging", true)
      .withOption("ordering", false)
      .withOption("info", false)
      .withOption("serverSide", true)
      .withDataProp("currencies")
      .withOption("processing", true)
      .withOption("bFilter", false)
      .withOption(
        "fnRowCallback",
        function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          $compile(nRow)($scope);
        }
      )
      .withOption("ajax", function (data, callback, settings) {
        var endIndex = data.start + data.length - 1;
        CurrencyService.getCurrencies(data.start, endIndex).then(function (
          response
        ) {
          callback({
            iTotalRecords: 1000,
            iTotalDisplayRecords: 1000,
            currencies: response.currencies,
          });
        });
      })
      .withDisplayLength(10)
      .withBootstrap();

    $scope.dtColumns = [
      DTColumnBuilder.newColumn("currency")
        .withTitle("Details")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          var tt_info =
            ' popover-placement="bottom" popover-trigger="\'mouseenter\'" uib-popover=' +
            ' "Assets Issuance details"';

          return (
            '<button type="button" class="btn btn-infinity btn-xs"  ' +
            tt_info +
            ' ng-controller="SearchCtrl"' +
            " ng-click=\"searchValue('" +
            data +
            "')\">" +
            '<i class="fa fa-list-ul" aria-hidden="true" style="width:15px;"></i>' +
            "</button>"
          );
        }),

      DTColumnBuilder.newColumn("code")
        .withTitle("Code")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return (
            '<a type="button" class="pointer" ng-click="openModal(\'' +
            data.toUpperCase() +
            "')\">" +
            data +
            "</a>"
          );
        }),

      DTColumnBuilder.newColumn("name").withTitle("Name").notSortable(),

      DTColumnBuilder.newColumn("accountRS")
        .withTitle("Issuer")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return searchTermFilter(data);
        }),

      DTColumnBuilder.newColumn("currentSupply")
        .withTitle("Supply")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return numericalStringFilter(
            quantityToShareFilter(data, row.decimals)
          );
        }),

      DTColumnBuilder.newColumn("numberOfExchanges")
        .withTitle("Exchanges")
        .notSortable(),

      DTColumnBuilder.newColumn("numberOfTransfers")
        .withTitle("Transfers")
        .notSortable(),

      DTColumnBuilder.newColumn("currency")
        .withTitle("View")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          var tt_trades =
            ' popover-placement="bottom" popover-trigger="\'mouseenter\'" uib-popover=' +
            ' "Exchanges"';

          var tt_transfer =
            ' popover-placement="bottom" popover-trigger="\'mouseenter\'" uib-popover=' +
            ' "Transfers"';

          var trades =
            '<button type="button" class="btn btn-default btn-xs" ' +
            tt_trades +
            " ng-click=\"openCurrencyExchanges('" +
            data +
            "','" +
            row.decimals +
            "')\">" +
            '<i class="fa fa-bar-chart" aria-hidden="true"></i>' +
            "</button>";

          var transfers =
            '<button type="button"class="btn btn-default btn-xs ' +
            tt_transfer +
            ' " ng-click="openCurrencyTransfer(\'' +
            data +
            "','" +
            row.decimals +
            "')\">" +
            '<i class="fa fa-user" aria-hidden="true" style="width:15px;"></i>' +
            "</button>";

          return trades + "&nbsp;" + transfers;
        }),
    ];

    $scope.openModal = function (currency) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: "currencies/currency.html",
        size: "lg",
        controller: "CurrencyCtrl",
        windowClass: "currency-modal-window",
        resolve: {
          params: function () {
            return {
              currency: currency,
              includeCounts: true,
            };
          },
        },
      });
    };

    $scope.openCurrencyExchanges = function (currency, decimals) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: "currencies/currency-exchange.html",
        size: "lg",
        controller: "CurrencyExchangeCtrl",
        windowClass: "currency-modal-window",
        resolve: {
          params: function () {
            return {
              currency: currency,
              decimals: decimals,
            };
          },
        },
      });
    };

    $scope.openCurrencyTransfer = function (currency, decimals) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: "currencies/currency-transfer.html",
        size: "lg",
        controller: "CurrencyTransferCtrl",
        windowClass: "currency-modal-window",
        resolve: {
          params: function () {
            return {
              currency: currency,
              decimals: decimals,
            };
          },
        },
      });
    };

    $scope.dtInstanceCallback = function (_dtInstance) {
      $scope.dtInstance = _dtInstance;
    };

    $scope.reloadCurrencies = function () {
      if ($scope.dtInstance) {
        $scope.dtInstance._renderer.rerender();
      }
    };
  },
]);

angular.module("currency").controller("CurrencyCtrl", [
  "$scope",
  "CurrencyService",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "$compile",
  "$uibModalInstance",
  "params",
  function (
    $scope,
    CurrencyService,
    DTOptionsBuilder,
    DTColumnBuilder,
    $compile,
    $uibModalInstance,
    params
  ) {
    $scope.currencyId = params.currency;
    $scope.includeCounts = params.includeCounts;

    $scope.cancel = function () {
      $uibModalInstance.dismiss("cancel");
    };

    $scope.loadDetails = function () {
      CurrencyService.getCurrency($scope.currencyId, $scope.includeCounts).then(
        function (response) {
          $scope.currencyDetails = response;
        }
      );
    };

    $scope.generateSearchLink = function (searchTerm) {
      var accountHtml =
        '<a href="" ng-controller="SearchCtrl" ng-click="searchValue(\'' +
        searchTerm +
        "')\">" +
        searchTerm +
        "</a>";
      return accountHtml;
    };
  },
]);

angular.module("currency").controller("CurrencyExchangeCtrl", [
  "$scope",
  "CurrencyService",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "$compile",
  "$uibModalInstance",
  "params",
  "amountTKNFilter",
  "timestampFilter",
  "amountTQTFilter",
  "searchTermFilter",
  "quantityToShareFilter",
  "numericalStringFilter",
  "quantToAmountFilter",
  "shareToQuantiyFilter",
  function (
    $scope,
    CurrencyService,
    DTOptionsBuilder,
    DTColumnBuilder,
    $compile,
    $uibModalInstance,
    params,
    amountTKNFilter,
    timestampFilter,
    amountTQTFilter,
    searchTermFilter,
    quantityToShareFilter,
    numericalStringFilter,
    quantToAmountFilter,
    shareToQuantiyFilter
  ) {
    $scope.currency = params.currency;
    $scope.decimals = params.decimals;

    $scope.dtOptions = DTOptionsBuilder.newOptions()
      .withPaginationType("numbers")
      .withDOM("frtip")
      .withOption("paging", true)
      .withOption("ordering", false)
      .withOption("info", false)
      .withOption("serverSide", true)
      .withDataProp("exchanges")
      .withOption("processing", true)
      .withOption("bFilter", false)
      .withOption(
        "fnRowCallback",
        function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          $compile(nRow)($scope);
        }
      )
      .withOption("ajax", function (data, callback, settings) {
        var endIndex = data.start + data.length - 1;
        CurrencyService.getExchanges(
          $scope.currency,
          data.start,
          endIndex
        ).then(function (response) {
          callback({
            iTotalRecords: 1000,
            iTotalDisplayRecords: 1000,
            exchanges: response.exchanges,
          });
        });
      })
      .withDisplayLength(10)
      .withBootstrap();

    $scope.dtColumns = [
      DTColumnBuilder.newColumn("offer")
        .withTitle("Details")
        .notSortable()

        .renderWith(function (data, type, row, meta) {
          var tt_info =
            ' popover-placement="bottom" popover-trigger="\'mouseenter\'" uib-popover=' +
            ' "Exchange Details"';

          return (
            '<button type="button" class="btn btn-infinity btn-xs"  ' +
            tt_info +
            ' ng-controller="SearchCtrl"' +
            " ng-click=\"searchValue('" +
            data +
            "')\">" +
            '<i class="fa fa-list-ul" aria-hidden="true"  style="width:15px;"  ></i>' +
            "</button>"
          );
        }),

      DTColumnBuilder.newColumn("timestamp")
        .withTitle("Date")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return $scope.formatDate(data);
        }),

      DTColumnBuilder.newColumn("rateTQT")
        .withTitle("Rate")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return numericalStringFilter(quantToAmountFilter(data));
        }),
      DTColumnBuilder.newColumn("units")
        .withTitle("Units")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return numericalStringFilter(
            quantityToShareFilter(data, $scope.decimals)
          );
        }),
      DTColumnBuilder.newColumn("buyerRS")
        .withTitle("Buyer")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return searchTermFilter(data);
        }),

      DTColumnBuilder.newColumn("sellerRS")
        .withTitle("Seller")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return searchTermFilter(data);
        }),
    ];

    $scope.cancel = function () {
      $uibModalInstance.dismiss("cancel");
    };
  },
]);

angular.module("currency").controller("CurrencyTransferCtrl", [
  "$scope",
  "CurrencyService",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "$compile",
  "$uibModalInstance",
  "params",
  "amountTKNFilter",
  "timestampFilter",
  "searchTermFilter",
  "quantityToShareFilter",
  "numericalStringFilter",
  "quantToAmountFilter",
  "shareToQuantiyFilter",
  function (
    $scope,
    CurrencyService,
    DTOptionsBuilder,
    DTColumnBuilder,
    $compile,
    $uibModalInstance,
    params,
    amountTKNFilter,
    timestampFilter,
    searchTermFilter,
    quantityToShareFilter,
    numericalStringFilter,
    quantToAmountFilter,
    shareToQuantiyFilter
  ) {
    $scope.currency = params.currency;
    $scope.decimals = params.decimals;

    $scope.dtOptions = DTOptionsBuilder.newOptions()
      .withPaginationType("numbers")
      .withDOM("frtip")
      .withOption("paging", true)
      .withOption("ordering", false)
      .withOption("info", false)
      .withOption("serverSide", true)
      .withDataProp("transfers")
      .withOption("processing", true)
      .withOption("bFilter", false)
      .withOption(
        "fnRowCallback",
        function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          $compile(nRow)($scope);
        }
      )
      .withOption("ajax", function (data, callback, settings) {
        var endIndex = data.start + data.length - 1;
        CurrencyService.getTransfers(
          $scope.currency,
          data.start,
          endIndex
        ).then(function (response) {
          callback({
            iTotalRecords: 1000,
            iTotalDisplayRecords: 1000,
            transfers: response.transfers,
          });
        });
      })
      .withDisplayLength(10)
      .withBootstrap();

    $scope.dtColumns = [
      DTColumnBuilder.newColumn("transfer")
        .withTitle("Details")
        .notSortable()

        .renderWith(function (data, type, row, meta) {
          var tt_info =
            ' popover-placement="bottom" popover-trigger="\'mouseenter\'" uib-popover=' +
            ' "Transfer Details"';

          return (
            '<button type="button" class="btn btn-infinity btn-xs"  ' +
            tt_info +
            ' ng-controller="SearchCtrl"' +
            " ng-click=\"searchValue('" +
            data +
            "')\">" +
            '<i class="fa fa-list-ul" aria-hidden="true"  style="width:15px;"  ></i>' +
            "</button>"
          );
        }),

      DTColumnBuilder.newColumn("units")
        .withTitle("Quantity")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return numericalStringFilter(
            quantityToShareFilter(data, $scope.decimals)
          );
        }),

      DTColumnBuilder.newColumn("timestamp")
        .withTitle("Date")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return $scope.formatDate(data);
        }),

      DTColumnBuilder.newColumn("senderRS")
        .withTitle("Sender")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return searchTermFilter(data);
        }),
      DTColumnBuilder.newColumn("recipientRS")
        .withTitle("Recipient")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return searchTermFilter(data);
        }),
    ];

    $scope.cancel = function () {
      $uibModalInstance.dismiss("cancel");
    };
  },
]);

angular.module("poll", [
  "baseBlockExplorer",
  "restangular",
  "datatables",
  "datatables.bootstrap",
  "ui.bootstrap",
  "ui.router",
  "nvd3",
]);

angular.module("poll").constant("pollConfig", {
  pollEndPoint: "api",
});

angular.module("poll").config([
  "RestangularProvider",
  "pollConfig",
  "$stateProvider",
  "$urlRouterProvider",
  "baseConfig",
  function (
    RestangularProvider,
    pollConfig,
    $stateProvider,
    $urlRouterProvider,
    baseConfig
  ) {
    RestangularProvider.setBaseUrl(baseConfig.apiUrl);
    RestangularProvider.setRestangularFields({
      options: "_options",
    });

    $stateProvider.state("blockExplorer.polls", {
      url: "^/polls",
      templateUrl: "./polls/polls.html",
      controller: "PollsCtrl",
    });
  },
]);

angular.module("poll").service("PollService", [
  "Restangular",
  "pollConfig",
  function (Restangular, pollConfig) {
    this.getPolls = function (firstIndex, lastIndex) {
      var params = {
        requestType: "getAllPolls",
        firstIndex: firstIndex,
        lastIndex: lastIndex,
        includeFinished: true,
      };
      return Restangular.all(pollConfig.pollEndPoint).customGET("", params);
    };

    this.getPoll = function (poll) {
      var params = {
        requestType: "getPoll",
        poll: poll,
      };
      return Restangular.all(pollConfig.pollEndPoint).customGET("", params);
    };

    this.getPollData = function (pollId) {
      var params = {
        requestType: "getPollResult",
        poll: pollId,
      };
      return Restangular.all(pollConfig.pollEndPoint).customGET("", params);
    };
  },
]);

angular.module("poll").controller("PollsCtrl", [
  "$scope",
  "PollService",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "$interval",
  "$uibModal",
  "$compile",
  "votingModelFilter",
  "amountTQTFilter",
  "isEnabledFilter",
  "baseConfig",
  "searchTermFilter",
  "quantityToShareFilter",
  "numericalStringFilter",
  "quantToAmountFilter",
  "shareToQuantiyFilter",
  "votingModelLabelFilter",
  function (
    $scope,
    PollService,
    DTOptionsBuilder,
    DTColumnBuilder,
    $interval,
    $uibModal,
    $compile,
    votingModelFilter,
    amountTQTFilter,
    isEnabledFilter,
    baseConfig,
    searchTermFilter,
    quantityToShareFilter,
    numericalStringFilter,
    quantToAmountFilter,
    shareToQuantiyFilter,
    votingModelLabelFilter
  ) {
    $scope.dtOptions = DTOptionsBuilder.newOptions()
      .withPaginationType("numbers")
      .withDOM("frtip")
      .withOption("paging", true)
      .withOption("ordering", false)
      .withOption("info", false)
      .withOption("responsive", true)
      .withOption("serverSide", true)
      .withDataProp("polls")
      .withOption("processing", true)
      .withOption("bFilter", false)
      .withOption(
        "fnRowCallback",
        function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          $compile(nRow)($scope);
        }
      )
      .withOption("ajax", function (data, callback, settings) {
        var endIndex = data.start + data.length - 1;
        PollService.getPolls(data.start, endIndex).then(function (response) {
          callback({
            iTotalRecords: 1000,
            iTotalDisplayRecords: 1000,
            polls: response.polls,
          });
        });
      })
      .withDisplayLength(10)
      .withBootstrap();

    $scope.dtColumns = [
      DTColumnBuilder.newColumn("offer")
        .withTitle("Details")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          var tt_info =
            ' popover-placement="bottom" popover-trigger="\'mouseenter\'" uib-popover=' +
            ' "Poll Details"';

          return (
            '<button type="button" class="btn btn-infinity btn-xs" style="" ng-click="openModal(\'' +
            row.poll +
            "')\">" +
            '<i class="fa fa-list-ul" aria-hidden="true"></i>' +
            "</button>"
          );
        }),

      DTColumnBuilder.newColumn("name")
        .withTitle("Name")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return data;
        }),

      DTColumnBuilder.newColumn("votingModel")
        .withTitle("Model")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return votingModelLabelFilter(data);
        }),

      DTColumnBuilder.newColumn("options")
        .withTitle("Options")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return data.length;
        }),
      DTColumnBuilder.newColumn("finished")
        .withTitle("Finished")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return isEnabledFilter(data);
        }),

      DTColumnBuilder.newColumn("finishHeight")
        .withTitle("Height")
        .notSortable(),

      DTColumnBuilder.newColumn("poll")
        .withTitle("View")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return (
            '<button type="button" class="btn btn-default btn-xs" ng-click="openPollData(\'' +
            data +
            "','" +
            row.name +
            "','" +
            row.description +
            '\')">  <i class="fa fa-bar-chart" aria-hidden="true" style="width:15px;"></i> </a>'
          );
        }),
    ];

    $scope.openModal = function (poll) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: "polls/poll.html",
        size: "lg",
        controller: "PollCtrl",
        windowClass: "poll-modal-window",
        resolve: {
          params: function () {
            return {
              pollId: poll,
              includeCounts: true,
            };
          },
        },
      });
    };

    $scope.openPollData = function (pollId, pollName, pollDescription) {
      var modalInstance = $uibModal.open({
        animation: true,
        templateUrl: "polls/poll-result.html",
        size: "sm",
        controller: "PollCtrl",
        windowClass: "poll-result-modal-window",
        resolve: {
          params: function () {
            return {
              pollId: pollId,
              pollName: pollName,
              pollDescription: pollDescription,
            };
          },
        },
      });
    };

    $scope.dtInstanceCallback = function (_dtInstance) {
      $scope.dtInstance = _dtInstance;
    };

    $scope.reloadPolls = function () {
      if ($scope.dtInstance) {
        $scope.dtInstance._renderer.rerender();
      }
    };
  },
]);

angular.module("poll").controller("PollCtrl", [
  "$scope",
  "PollService",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "$compile",
  "$uibModalInstance",
  "params",
  "numericalStringFilter",
  "baseConfig",
  "numberStringFilter",
  function (
    $scope,
    PollService,
    DTOptionsBuilder,
    DTColumnBuilder,
    $compile,
    $uibModalInstance,
    params,
    numericalStringFilter,
    baseConfig,
    numberStringFilter
  ) {
    $scope.pollId = params.pollId;
    $scope.pollName = params.pollName;
    $scope.pollDescription = params.pollDescription;

    $scope.loadDetails = function () {
      PollService.getPoll($scope.pollId).then(function (response) {
        $scope.poll = response;
      });
    };

    $scope.loadPollResults = function () {
      PollService.getPollData($scope.pollId).then(function (response) {
        $scope.pollResults = response;
        $scope.pollLabels = response.options;
        $scope.pollData = getPollData(response.options, response.results);
        var divisor = 1;
        if (response.votingModel !== 0) {
          divisor = baseConfig.TOKEN_QUANTS;
        }
        $scope.total = sumResults(response.results, divisor);
        $scope.pollResultTableDate = buildPollDataArray(
          response.options,
          response.results,
          response.votingModel
        );
      });
    };

    function sumResults(results, divisor) {
      var sum = 0;
      for (var i = 0; i < results.length; i++) {
        sum = sum + results[i].result / divisor;
      }
      return sum;
    }

    function buildPollDataArray(labels, results, votingModel) {
      var divisor = 1;
      if (votingModel !== 0) {
        divisor = baseConfig.TOKEN_QUANTS;
      }
      var finalResults = [];
      var total = sumResults(results, divisor);
      for (var i = 0; i < labels.length; i++) {
        var result = {};
        var pollResult = results[i];
        result.optionName = labels[i];
        result.result = pollResult.result / divisor || 0;
        result.weight = pollResult.weight / divisor;
        if (total !== 0) {
          result.percentage = (result.result * 100) / total;
        } else {
          result.percentage = 0;
        }
        finalResults.push(result);
      }
      return finalResults;
    }

    function getPollData(labels, results) {
      var optionSize = results.length;
      var resultArray = [];
      for (var i = 0; i < optionSize; i++) {
        var resultObject = {};
        resultObject.key = labels[i];
        resultObject.value = results[i].result / 100000000;
        if (!resultObject.value) {
          resultObject.value = 0;
        }
        resultArray[i] = resultObject;
      }
      return resultArray;
    }

    $scope.options = {
      chart: {
        type: "pieChart",
        height: 350,
        showLegend: true,
        noData: "No Votes avaiable",
        margin: {
          top: 30,
          right: 75,
          bottom: 50,
          left: 75,
        },
        bars: {
          forceY: [0],
        },
        bars2: {
          forceY: [0],
        },
        xAxis: {
          axisLabel: "X Axis",
        },
        x2Axis: {
          showMaxMin: false,
        },
        y1Axis: {
          axisLabel: "Y1 Axis",
          axisLabelDistance: 12,
        },
        y2Axis: {
          axisLabel: "Y2 Axis",
        },
        x: function (d) {
          return d.key;
        },
        y: function (d) {
          return d.value;
        },
        tooltip: {
          valueFormatter: function (d) {
            return numberStringFilter((d * 100) / $scope.total) + " %";
          },
        },
      },
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss("cancel");
    };
  },
]);

angular.module("transactions", [
  "baseBlockExplorer",
  "restangular",
  "datatables",
  "datatables.bootstrap",
  "ui.bootstrap",
  "ui.router",
]);

angular.module("transactions").constant("transactionsConfig", {
  transactionsEndPoint: "api",
});

angular.module("transactions").config([
  "RestangularProvider",
  "transactionsConfig",
  "$stateProvider",
  "$urlRouterProvider",
  "baseConfig",
  function (
    RestangularProvider,
    transactionsConfig,
    $stateProvider,
    $urlRouterProvider,
    baseConfig
  ) {
    RestangularProvider.setBaseUrl(baseConfig.apiUrl);

    $stateProvider.state("blockExplorer.transactions", {
      url: "^/transactions?account",
      templateUrl: "./transactions/transactions.html",
      controller: "TransactionsCtrl",
    });
  },
]);

angular.module("transactions").service("TransactionsService", [
  "Restangular",
  "transactionsConfig",
  "baseConfig",
  function (Restangular, transactionsConfig, baseConfig) {
    this.getTransactions = function (firstIndex, lastIndex) {
      var params = {
        requestType: "getTransactions",
        firstIndex: firstIndex,
        lastIndex: lastIndex,
        includePhasingResult: true,
      };
      return Restangular.all(baseConfig.baseEndPoint).customGET("", params);
    };

    this.getTransactionsOfAccount = function (account, firstIndex, lastIndex) {
      var params = {
        requestType: "getBlockchainTransactions",
        account: account,
        firstIndex: firstIndex,
        lastIndex: lastIndex,
      };

      return Restangular.all(transactionsConfig.transactionsEndPoint).customGET(
        "",
        params
      );
    };
  },
]);

angular.module("transactions").controller("TransactionsCtrl", [
  "$state",
  "$rootScope",
  "$scope",
  "TransactionsService",
  "baseConfig",
  "transactionsConfig",
  "amountTQTFilter",
  "timestampFilter",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "$interval",
  "$uibModal",
  "$compile",
  "searchTermFilter",
  "quantityToShareFilter",
  "numericalStringFilter",
  "quantToAmountFilter",
  "shareToQuantiyFilter",
  "transactionIconSubTypeFilter",
  function (
    $state,
    $rootScope,
    $scope,
    TransactionsService,
    baseConfig,
    transactionsConfig,
    amountTQTFilter,
    timestampFilter,
    DTOptionsBuilder,
    DTColumnBuilder,
    $interval,
    $uibModal,
    $compile,
    searchTermFilter,
    quantityToShareFilter,
    numericalStringFilter,
    quantToAmountFilter,
    shareToQuantiyFilter,
    transactionIconSubTypeFilter
  ) {
    if ($state.params.account) {
      $rootScope.$emit("searchWith", { account: $state.params.account });
    }

    $scope.dtOptions = DTOptionsBuilder.newOptions()
      .withPaginationType("numbers")
      .withDOM("frtip")
      .withOption("paging", true)
      .withOption("ordering", false)
      .withOption("info", false)
      .withOption("serverSide", true)
      .withDataProp("transactions")
      .withOption("processing", true)
      .withOption("bFilter", false)
      .withOption(
        "fnRowCallback",
        function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          $compile(nRow)($scope);
        }
      )
      .withOption("ajax", function (data, callback, settings) {
        var endIndex = data.start + data.length - 1;
        TransactionsService.getTransactions(data.start, endIndex).then(
          function (response) {
            callback({
              iTotalRecords: 1000,
              iTotalDisplayRecords: 1000,
              transactions: response.transactions,
            });
          }
        );
      })
      .withDisplayLength(10)
      .withBootstrap();

    $scope.dtColumns = [
      DTColumnBuilder.newColumn("Id")
        .withTitle("Details")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          var details =
            '<a type="button" class="btn btn-infinity btn-xs" ng-controller="SearchCtrl"' +
            " ng-click=\"searchValue('" +
            row.fullHash +
            "')\">" +
            '<i class="fa fa-list-ul" aria-hidden="true"></i>' +
            "</a>";
          return details;
        }),
      DTColumnBuilder.newColumn("type")
        .withTitle("Type")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return transactionIconSubTypeFilter(data, row.subtype);
        }),
      DTColumnBuilder.newColumn("timestamp")
        .withTitle("Date")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return $scope.formatDate(data);
        }),
      DTColumnBuilder.newColumn("amountTQT")
        .withTitle("Amount")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return numericalStringFilter(quantToAmountFilter(data));
        }),
      DTColumnBuilder.newColumn("feeTQT")
        .withTitle("Fee")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return numericalStringFilter(quantToAmountFilter(data));
        }),
      DTColumnBuilder.newColumn("confirmations")
        .withTitle("Conf.")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return getTransactionConf(data);
        }),
      DTColumnBuilder.newColumn("senderRS")
        .withTitle("Sender")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          if (row.senderId === "0") {
            return "";
          }

          return searchTermFilter(data);
        }),
      DTColumnBuilder.newColumn("recipientRS")
        .withTitle("Recipient")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return searchTermFilter(data);
        }),
    ];

    function getTransactionConf(value) {
      if (value === 0) {
        return '<span class="label label-default">' + value + "</span>";
      } else if (value > 0 && value < 10) {
        return '<span class="label label-danger">' + value + "</span>";
      } else if (value >= 10 && value < 100) {
        return '<span class="label label-warning">' + value + "</span>";
      } else if (value >= 100 && value < 720) {
        return '<span class="label label-success">' + value + "</span>";
      } else {
        return '<span class="label label-success">+720</span>';
      }
    }

    function getTransactionTypeGlyphicon(value) {
      switch (value) {
        case 0:
          return "glyphicon-usd";
        case 1:
          return "glyphicon-envelope";
        case 2:
          return "glyphicon-signal";
        case 3:
          return "glyphicon-shopping-cart";
        case 4:
          return "glyphicon-info-sign";
        case 5:
          return "glyphicon-random";
        case 6:
          return "glyphicon-save";
        case 7:
          return "glyphicon-link";
      }
    }

    $scope.dtInstanceCallback = function (_dtInstance) {
      $scope.dtInstance = _dtInstance;
    };

    $scope.reloadTransactions = function () {
      if ($scope.dtInstance) {
        $scope.dtInstance._renderer.rerender();
      }
    };
  },
]);

angular.module("unconfirmedTransactions", [
  "baseBlockExplorer",
  "restangular",
  "datatables",
  "datatables.bootstrap",
  "ui.bootstrap",
  "ui.router",
]);

angular
  .module("unconfirmedTransactions")
  .constant("unconfirmedTransactionsConfig", {
    unconfirmedTransactionsEndPoint: "api",
  });

angular.module("unconfirmedTransactions").config([
  "RestangularProvider",
  "unconfirmedTransactionsConfig",
  "$stateProvider",
  "$urlRouterProvider",
  "baseConfig",
  function (
    RestangularProvider,
    unconfirmedTransactionsConfig,
    $stateProvider,
    $urlRouterProvider,
    baseConfig
  ) {
    RestangularProvider.setBaseUrl(baseConfig.apiUrl);

    $stateProvider.state("blockExplorer.unconfirmedTransactions", {
      url: "^/unconfirmedTransactions",
      templateUrl: "./unconfirmed/unconfirmed.html",
      controller: "UnconfirmedTransactionsCtrl",
    });
  },
]);

angular
  .module("unconfirmedTransactions")
  .service("UnconfirmedTransactionsService", [
    "Restangular",
    "unconfirmedTransactionsConfig",
    function (Restangular, unconfirmedTransactionsConfig) {
      this.getUnconfirmedTransactions = function () {
        var params = {
          requestType: "getUnconfirmedTransactions",
        };
        return Restangular.all(
          unconfirmedTransactionsConfig.unconfirmedTransactionsEndPoint
        ).customGET("", params);
      };
    },
  ]);

angular
  .module("unconfirmedTransactions")
  .controller("UnconfirmedTransactionsCtrl", [
    "$scope",
    "UnconfirmedTransactionsService",
    "baseConfig",
    "unconfirmedTransactionsConfig",
    "amountTQTFilter",
    "timestampFilter",
    "DTOptionsBuilder",
    "DTColumnBuilder",
    "$interval",
    "$uibModal",
    "$compile",
    "searchTermFilter",
    "quantityToShareFilter",
    "numericalStringFilter",
    "quantToAmountFilter",
    "shareToQuantiyFilter",
    "transactionIconSubTypeFilter",
    function (
      $scope,
      UnconfirmedTransactionsService,
      baseConfig,
      unconfirmedTransactionsConfig,
      amountTQTFilter,
      timestampFilter,
      DTOptionsBuilder,
      DTColumnBuilder,
      $interval,
      $uibModal,
      $compile,
      searchTermFilter,
      quantityToShareFilter,
      numericalStringFilter,
      quantToAmountFilter,
      shareToQuantiyFilter,
      transactionIconSubTypeFilter
    ) {
      $scope.dtOptions = DTOptionsBuilder.newOptions()
        .withPaginationType("numbers")
        .withDOM("frtip")
        .withOption("paging", true)
        .withOption("ordering", false)
        .withOption("info", false)
        .withOption("serverSide", false)
        .withDataProp("unconfirmedTransactions")
        .withOption("paging", true)
        .withOption("ordering", false)
        .withOption("processing", false)
        .withOption("bFilter", false)
        .withOption(
          "fnRowCallback",
          function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
            $compile(nRow)($scope);
          }
        )
        .withOption("ajax", function (data, callback, settings) {
          UnconfirmedTransactionsService.getUnconfirmedTransactions().then(
            function (response) {
              callback({
                iTotalRecords: response.unconfirmedTransactions.length,
                iTotalDisplayRecords: response.unconfirmedTransactions.length,
                unconfirmedTransactions: response.unconfirmedTransactions,
              });
            }
          );
        })
        .withDisplayLength(10)
        .withBootstrap();

      $scope.dtColumns = [
        DTColumnBuilder.newColumn("transaction")
          .withTitle("Details")
          .notSortable()
          .renderWith(function (data, type, row, meta) {
            var details =
              '<a type="button" class="btn btn-infinity btn-xs" ng-controller="SearchCtrl"' +
              " ng-click=\"searchValue('" +
              data +
              "')\">" +
              '<i class="fa fa-list-ul" aria-hidden="true"></i>' +
              "</a>";
            return details;
          }),

        DTColumnBuilder.newColumn("type")
          .withTitle("Type")
          .notSortable()
          .renderWith(function (data, type, row, meta) {
            return transactionIconSubTypeFilter(data, row.subtype);
          }),

        DTColumnBuilder.newColumn("senderRS")
          .withTitle("Sender")
          .notSortable()
          .renderWith(function (data, type, row, meta) {
            return searchTermFilter(data);
          }),

        DTColumnBuilder.newColumn("recipientRS")
          .withTitle("Recipient")
          .notSortable()
          .renderWith(function (data, type, row, meta) {
            return searchTermFilter(data);
          }),

        DTColumnBuilder.newColumn("recipientRS")
          .withTitle("Conf.")
          .notSortable()
          .renderWith(function (data, type, row, meta) {
            return '<span class="label label-default">0</span>';
          }),
        DTColumnBuilder.newColumn("feeTQT")
          .withTitle("Fee")
          .notSortable()
          .renderWith(function (data, type, row, meta) {
            return numericalStringFilter(quantToAmountFilter(data));
          }),
        DTColumnBuilder.newColumn("amountTQT")
          .withTitle("Amount")
          .notSortable()
          .renderWith(function (data, type, row, meta) {
            return numericalStringFilter(quantToAmountFilter(data));
          }),
        DTColumnBuilder.newColumn("timestamp")
          .withTitle("Date")
          .notSortable()
          .renderWith(function (data, type, row, meta) {
            return $scope.formatDate(data);
          }),
      ];

      $scope.dtInstanceCallback = function (_dtInstance) {
        $scope.dtInstance = _dtInstance;
      };

      $scope.reloadUnconfirmedTransactions = function () {
        if ($scope.dtInstance) {
          $scope.dtInstance._renderer.rerender();
        }
      };
    },
  ]);

angular.module("accounts", [
  "restangular",
  "datatables",
  "datatables.bootstrap",
  "ui.bootstrap",
  "ui.router",
]);

angular.module("accounts").constant("accountsConfig", {
  accountsEndPoint: "api",
});

angular.module("accounts").config([
  "RestangularProvider",
  "accountsConfig",
  "$stateProvider",
  "$urlRouterProvider",
  "baseConfig",
  function (
    RestangularProvider,
    accountsConfig,
    $stateProvider,
    $urlRouterProvider,
    baseConfig
  ) {
    RestangularProvider.setBaseUrl(baseConfig.apiUrl);

    $stateProvider.state("blockExplorer.accounts", {
      url: "^/accounts",
      templateUrl: "./accounts/accounts.html",
      controller: "AccountsCtrl",
    });
  },
]);

angular.module("accounts").service("AccountService", [
  "Restangular",
  "accountsConfig",
  function (Restangular, accountsConfig) {
    this.getAccounts = function (firstIndex, lastIndex) {
      var params = {
        requestType: "getAccounts",
        firstIndex: firstIndex,
        lastIndex: lastIndex,
      };
      return Restangular.all(accountsConfig.accountsEndPoint).customGET(
        "",
        params
      );
    };

    this.searchAccount = function (account) {
      var params = {
        requestType: "getAccount",
        account: account,
        includeAssets: true,
        includeCurrencies: true,
        includeEffectiveBalance: true,
      };
      return Restangular.all(accountsConfig.searchEndPoint).customGET(
        "",
        params
      );
    };

    this.getBalance = function (account) {
      var params = {
        requestType: "getBalance",
        account: account,
      };
      return Restangular.all(accountsConfig.searchEndPoint).customGET(
        "",
        params
      );
    };

    this.getAccountAssets = function (account) {
      var params = {
        requestType: "getAccountAssets",
        account: account,
        includeAssetInfo: true,
      };
      return Restangular.all(accountsConfig.accountsEndPoint).customGET(
        "",
        params
      );
    };

    this.getAccountCurrencies = function (account) {
      var params = {
        requestType: "getAccountCurrencies",
        account: account,
        includeCurrencyInfo: true,
      };
      return Restangular.all(accountsConfig.accountsEndPoint).customGET(
        "",
        params
      );
    };

    this.getAccountPolls = function (account) {
      var params = {
        requestType: "getPolls",
        account: account,
        includeFinished: true,
      };
      return Restangular.all(accountsConfig.accountsEndPoint).customGET(
        "",
        params
      );
    };

    this.getAccountAliases = function (account) {
      var params = {
        requestType: "getAliases",
        account: account,
      };
      return Restangular.all(accountsConfig.accountsEndPoint).customGET(
        "",
        params
      );
    };
  },
]);

angular.module("accounts").controller("AccountsCtrl", [
  "$scope",
  "AccountService",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "$interval",
  "$uibModal",
  "$compile",
  "baseConfig",
  function (
    $scope,
    AccountService,
    DTOptionsBuilder,
    DTColumnBuilder,
    $interval,
    $uibModal,
    $compile,
    baseConfig
  ) {
    $scope.dtOptions = DTOptionsBuilder.newOptions()
      .withPaginationType("numbers")
      .withOption("serverSide", true)
      .withDataProp("accounts")
      .withOption("processing", true)
      .withOption("bFilter", false)
      .withOption(
        "fnRowCallback",
        function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          $compile(nRow)($scope);
        }
      )
      .withOption("ajax", function (data, callback, settings) {
        var endIndex = data.start + data.length - 1;
        AccountService.getAccounts(data.start, endIndex).then(function (
          response
        ) {
          callback({
            iTotalRecords: 1000,
            iTotalDisplayRecords: 1000,
            accounts: response.accounts,
          });
        });
      })
      .withDisplayLength(10)
      .withBootstrap();

    $scope.dtColumns = [
      DTColumnBuilder.newColumn("accountRS")
        .withTitle("Account")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return (
            '<a href="" ng-controller="SearchCtrl" ng-click="searchValue(\'' +
            data +
            "')\">" +
            data +
            "</a>"
          );
        }),
      DTColumnBuilder.newColumn("accountName").withTitle("Name").notSortable(),
      DTColumnBuilder.newColumn("accountDescription")
        .withTitle("Description")
        .notSortable(),
    ];

    $scope.dtInstanceCallback = function (_dtInstance) {
      $scope.dtInstance = _dtInstance;
    };
    $scope.reloadAccounts = function () {
      if ($scope.dtInstance) {
        $scope.dtInstance._renderer.rerender();
      }
    };
  },
]);

angular.module("distributions", [
  "restangular",
  "datatables",
  "datatables.bootstrap",
  "ui.bootstrap",
  "ui.router",
]);

angular.module("distributions").constant("distributionsConfig", {
  distributionsEndPoint: "api",
});

angular.module("distributions").config([
  "RestangularProvider",
  "distributionsConfig",
  "$stateProvider",
  "$urlRouterProvider",
  "baseConfig",
  function (
    RestangularProvider,
    distributionsConfig,
    $stateProvider,
    $urlRouterProvider,
    baseConfig
  ) {
    RestangularProvider.setBaseUrl(baseConfig.apiUrl);

    $stateProvider.state("blockExplorer.distributions", {
      url: "^/distributions",
      templateUrl: "./distributions/distributions.html",
      controller: "DistributionsCtrl",
    });
  },
]);

angular.module("distributions").service("DistributionService", [
  "Restangular",
  "distributionsConfig",
  "baseConfig",
  function (Restangular, distributionsConfig, baseConfig) {
    this.getAccountBalances = function ({
      firstIndex,
      lastIndex,
      includeDistribution,
      distributionStart,
      distributionEnd,
      interval,
    }) {
      const params = {
        requestType: "getAccountBalances",
        adminPassword: "dmluaXZpYTpwYXNzd29yZA==",
        ...(firstIndex !== undefined && { firstIndex }),
        ...(lastIndex !== undefined && { lastIndex }),
        ...(includeDistribution !== undefined && { includeDistribution }),
        ...(distributionStart !== undefined && { distributionStart }),
        ...(distributionEnd !== undefined && { distributionEnd }),
        ...(interval !== undefined && { interval }),
        ...(distributionEnd !== undefined && { distributionEnd }),
      };

      Restangular.setBaseUrl(baseConfig.apiUrl);
      return Restangular.all(
        distributionsConfig.distributionsEndPoint
      ).customGET("", params);
    };

    this.getDistributions = function (
      minAccountBalance,
      maxAccountBalance,
      slices
    ) {
      var params = {
        requestType: "getDistributions",
        minAccountBalance: minAccountBalance,
        maxAccountBalance: maxAccountBalance,
        slices: slices,
      };

      Restangular.setBaseUrl(baseConfig.apiUrl);
      return Restangular.all(
        distributionsConfig.distributionsEndPoint
      ).customGET("", params);
    };
  },
]);

angular.module("distributions").controller("DistributionsCtrl", [
  "$scope",
  "DistributionService",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "$interval",
  "$uibModal",
  "$compile",
  "baseConfig",
  "numericalStringFilter",
  "quantToAmountFilter",
  function (
    $scope,
    DistributionService,
    DTOptionsBuilder,
    DTColumnBuilder,
    $interval,
    $uibModal,
    $compile,
    baseConfig,
    numericalStringFilter,
    quantToAmountFilter
  ) {
    $scope.dtOptions = DTOptionsBuilder.newOptions()
      .withPaginationType("numbers")
      .withDOM("frtip")
      .withOption("responsive", true)
      .withOption("ordering", false)
      .withOption("info", false)
      .withOption("serverSide", true)
      .withDataProp("balances")
      .withOption("paging", true)
      .withOption("processing", true)
      .withOption("bFilter", false)
      .withOption(
        "fnRowCallback",
        function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          $compile(nRow)($scope);
        }
      )
      .withOption("ajax", function (data, callback, settings) {
        const endIndex = data.start + data.length - 1;
        DistributionService.getAccountBalances({
          firstIndex: data.start,
          lastIndex: endIndex,
        }).then(function (response) {
          callback({
            iTotalRecords: 1000,
            iTotalDisplayRecords: 1000,
            balances: JSON.parse(response.balances),
          });
        });
      })
      .withDisplayLength(10)
      .withBootstrap();

    $scope.dtColumns = [
      DTColumnBuilder.newColumn("accountRs")
        .withTitle("Account")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return (
            '<a href="" ng-controller="SearchCtrl" ng-click="searchValue(\'' +
            data +
            "')\">" +
            data +
            "</a>"
          );
        }),
      DTColumnBuilder.newColumn("balanceTQT")
        .withTitle("Balance")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return numericalStringFilter(quantToAmountFilter(data));
        }),
    ];

    $scope.dtInstanceCallback = function (_dtInstance) {
      $scope.dtInstance = _dtInstance;
    };
    $scope.reloadBalances = function () {
      if ($scope.dtInstance) {
        $scope.dtInstance._renderer.rerender();
      }
    };

    $scope.dtDistributionOptions = DTOptionsBuilder.newOptions()
      .withPaginationType("numbers")
      .withDOM("frtip")
      .withOption("responsive", true)
      .withOption("ordering", false)
      .withOption("info", false)
      .withOption("serverSide", false)
      .withDataProp("distributions")
      .withOption("paging", true)
      .withOption("processing", false)
      .withOption("bFilter", false)
      .withOption(
        "fnRowCallback",
        function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          $compile(nRow)($scope);
        }
      )
      .withOption("ajax", function (data, callback, settings) {
        DistributionService.getDistributions(
          100000000000,
          9 * 1000000000 * 100000000,
          10
        ).then(function (response) {
          callback({
            iTotalRecords: response.distributions.length,
            iTotalDisplayRecords: response.distributions.length,
            distributions: response.distributions,
          });
        });
      })
      .withDisplayLength(10)
      .withBootstrap();

    $scope.dtDistributionColumns = [
      DTColumnBuilder.newColumn("from")
        .withTitle("From")
        .renderWith(function (data, type, row, meta) {
          return quantToAmountFilter(data);
        }),
      DTColumnBuilder.newColumn("to")
        .withTitle("To")
        .renderWith(function (data, type, row, meta) {
          return quantToAmountFilter(data);
        }),
      DTColumnBuilder.newColumn("accountsAmount")
        .withTitle("#")
        .renderWith(function (data, type, row, meta) {
          return data;
        }),
    ];

    $scope.dtDistributionInstanceCallback = function (_dtInstance) {
      $scope.dtDistributionInstance = _dtInstance;
    };
    $scope.reloadDistributions = function () {
      if ($scope.dtDistributionInstance) {
        $scope.dtDistributionInstance._renderer.rerender();
      }
    };
  },
]);

var searchApp = angular.module("search", [
  "baseBlockExplorer",
  "restangular",
  "datatables",
  "blocks",
  "accounts",
  "transactions",
  "datatables.bootstrap",
  "ui.bootstrap",
  "poll",
  "ui.router",
  "ja.qr",
  "nvd3",
]);

angular.module("search").constant("searchConfig", {
  searchEndPoint: "api",
  searchAccountString: "Cx0",
});

angular.module("search").config([
  "RestangularProvider",
  "searchConfig",
  "$stateProvider",
  "$urlRouterProvider",
  "baseConfig",
  function (
    RestangularProvider,
    searchConfig,
    $stateProvider,
    $urlRouterProvider,
    baseConfig
  ) {
    RestangularProvider.setBaseUrl(baseConfig.apiUrl);
    RestangularProvider.setRestangularFields({
      options: "_options",
    });

    $stateProvider.state("blockExplorer.search", {
      url: "^/search",
      templateUrl: "./search/search.html",
      controller: "SearchCtrl",
    });
  },
]);

angular.module("search").service("SearchService", [
  "Restangular",
  "BlocksService",
  "searchConfig",
  function (Restangular, BlocksService, searchConfig) {
    this.searchBlocks = function (searchTerm) {
      return BlocksService.getBlockDetails(searchTerm, true);
    };

    this.searchTransactionById = function (searchTerm) {
      var params = {
        requestType: "getTransaction",
        transaction: searchTerm,
      };
      return Restangular.all(searchConfig.searchEndPoint).customGET("", params);
    };

    this.searchBlockById = function (searchTerm) {
      var params = {
        requestType: "getBlock",
        block: searchTerm,
      };
      return Restangular.all(searchConfig.searchEndPoint).customGET("", params);
    };

    this.searchAccounts = function (searchTerm) {
      var params = {
        requestType: "getAccount",
        account: searchTerm,
        includeAssets: true,
        includeCurrencies: true,
        includeEffectiveBalance: true,
        includeLessors: true,
      };
      return Restangular.all(searchConfig.searchEndPoint).customGET("", params);
    };

    this.searchTransactions = function (searchTerm) {
      var params = {
        requestType: "getTransaction",
        fullHash: searchTerm,
        includePhasingResult: true,
      };
      return Restangular.all(searchConfig.searchEndPoint).customGET("", params);
    };
  },
]);

angular.module("search").controller("SearchCtrl", [
  "$rootScope",
  "$scope",
  "SearchService",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "$interval",
  "$uibModal",
  "$compile",
  "searchConfig",
  "$q",
  "votingModelFilter",
  function (
    $rootScope,
    $scope,
    SearchService,
    DTOptionsBuilder,
    DTColumnBuilder,
    $interval,
    $uibModal,
    $compile,
    searchConfig,
    $q,
    votingModelFilter
  ) {
    var deregister = $rootScope.$on("searchWith", function (evt, data) {
      $scope.searchValue(data.account);
    });
    $scope.$on("$destroy", deregister);

    $scope.showSearchBar = false;

    var errorHandler = function (errorMessage) {
      $uibModal.open({
        animation: true,
        templateUrl: "search/search-error.html",
        size: "sm",
        controller: "ErrorSearchCtrl",

        resolve: {
          params: function () {
            return {
              message: errorMessage,
            };
          },
        },
      });
    };

    $scope.searchValue = function (searchTerm) {
      if (searchTerm) {
        if (searchTerm.startsWith(searchConfig.searchAccountString)) {
          SearchService.searchAccounts(searchTerm).then(function (response) {
            if (!response.errorCode) {
              var result = $uibModal.open({
                animation: true,
                templateUrl: "search/search-account-result.html",
                size: "lg",
                controller: "AccountSearchCtrl",
                windowClass: "block-modal-window",
                resolve: {
                  params: function () {
                    return {
                      account: response,
                      accountId: searchTerm,
                    };
                  },
                },
              });
            } else {
              errorHandler(searchTerm + " account doesn't exists ");
            }
          });
        } else if (!isNaN(searchTerm)) {
          var blockHeightSearch = SearchService.searchBlocks(searchTerm);
          var blockIdSearch = SearchService.searchBlockById(searchTerm);
          var transactionSearch =
            SearchService.searchTransactionById(searchTerm);
          $q.all([blockHeightSearch, blockIdSearch, transactionSearch]).then(
            function (results) {
              var resultSize = results.length;
              for (var i = 0; i < resultSize; i++) {
                var response = results[i];
                if (!response.errorCode) {
                  if (!response.transaction) {
                    $uibModal.open({
                      animation: true,
                      templateUrl: "blocks/block.html",
                      size: "lg",
                      controller: "BlockCtrl",
                      windowClass: "block-modal-window",
                      resolve: {
                        /* jshint ignore:start */
                        params: function () {
                          return {
                            height: response.height,
                            includeTransactions: true,
                          };
                        },
                        /* jshint ignore:end */
                      },
                    });
                    break;
                  } else {
                    $uibModal.open({
                      animation: true,
                      templateUrl: "search/search-transaction-result.html",
                      size: "lg",
                      controller: "TransactionSearchCtrl",
                      windowClass: "block-modal-window ",
                      resolve: {
                        /* jshint ignore:start */
                        params: function () {
                          return {
                            transaction: response,
                          };
                        },
                        /* jshint ignore:end */
                      },
                    });
                    break;
                  }
                }
              }
              if (resultSize === 0) {
                errorHandler(
                  searchTerm + " Block or transaction doesn't exists "
                );
              }
            }
          );
        } else {
          SearchService.searchTransactions(searchTerm).then(function (
            response
          ) {
            if (!response.errorCode) {
              $uibModal.open({
                animation: true,
                templateUrl: "search/search-transaction-result.html",
                size: "lg",
                controller: "TransactionSearchCtrl",
                windowClass: "block-modal-window ",
                resolve: {
                  params: function () {
                    console.log({res: response})
                    return {
                      transaction: response,
                    };
                  },
                },
              });
            } else {
              // Add modal for error messages

              errorHandler(
                searchTerm + " is not valid account, block or transaction"
              );
            }
          });
        }
      }
    };

    $scope.search = function () {
      var searchTerm = $scope.searchTerm;
      $scope.searchValue(searchTerm);
    };
  },
]);

angular.module("search").controller("AccountSearchCtrl", [
  "$scope",
  "DTOptionsBuilder",
  "TransactionsService",
  "timestampFilter",
  "amountTQTFilter",
  "amountTKNFilter",
  "isEmptyFilter",
  "DTColumnBuilder",
  "$compile",
  "$uibModalInstance",
  "$q",
  "params",
  "AccountService",
  "supplyFilter",
  "votingModelFilter",
  "currencyModelFilter",
  "searchTermFilter",
  "quantityToShareFilter",
  "numericalStringFilter",
  "quantToAmountFilter",
  "shareToQuantiyFilter",
  "transactionIconSubTypeFilter",
  "isEnabledFilter",
  function (
    $scope,
    DTOptionsBuilder,
    TransactionsService,
    timestampFilter,
    amountTQTFilter,
    amountTKNFilter,
    isEmptyFilter,
    DTColumnBuilder,
    $compile,
    $uibModalInstance,
    $q,
    params,
    AccountService,
    supplyFilter,
    votingModelFilter,
    currencyModelFilter,
    searchTermFilter,
    quantityToShareFilter,
    numericalStringFilter,
    quantToAmountFilter,
    shareToQuantiyFilter,
    transactionIconSubTypeFilter,
    isEnabledFilter
  ) {
    $scope.cancel = function () {
      $uibModalInstance.dismiss("cancel");
    };

    $scope.showResult = function () {
      $scope.account = params.account;
      $scope.accountRs = params.account.accountRS;

      var protocol = location.protocol;
      var host = location.host;
      $scope.accountUrl = `${protocol}//${host}/#!/transactions?account=${params.account.accountRS}`;
    };

    $scope.qrCode = function () {
      $scope.accountRs = params.account.accountRS;
    };

    $scope.dtOptionsTransactions = DTOptionsBuilder.newOptions()
      .withPaginationType("numbers")
      .withDOM("frtip")
      .withOption("serverSide", true)
      .withDataProp("data")
      .withOption("processing", true)
      .withOption("responsive", true)
      .withOption("paging", true)
      .withOption("ordering", false)
      .withOption("info", false)
      .withOption("bFilter", false)
      .withOption(
        "fnRowCallback",
        function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          $compile(nRow)($scope);
        }
      )
      .withOption("ajax", function (data, callback, settings) {
        var endIndex = data.start + data.length - 1;
        TransactionsService.getTransactionsOfAccount(
          params.accountId,
          data.start,
          endIndex
        ).then(function (response) {
          var res = response.transactions;
          if (params.accountId == "Cx0-P92J-WQYH-JQ7W-D2CF6") {
            res = [
              {
                "signature": "52c52a617134c7fe3633da9a5b4fd4d408c352154fa23620b3b1c6e2d12ce70adf87a4e6260ea7ad21c410fa3e3234a80156387d05248923f04870c66988937c",
                "transactionIndex": 0,
                "type": 9,
                "phased": false,
                "ecBlockId": "8303612992552195545",
                "signatureHash": "8b3cc770fc877f76c4b5b32eb17f3248ba7ee601c88d8e2631ee5d4f22684e51",
                "amountTQT": "9654640147732791",
                "attachment": {
                  "version.CallSmartContract": 1,
                  "data": "19ab453c0000000000000000000000009add326603270658376155be70cdb138c4a103ec",
                  "gas": "1667500",
                  "from": "d3d11753833a5f3523b4893dc860f91fc66b8046",
                  "to": "de3b8fb43a43ce6286178b93a036ceb54016adde",
                  "nonce": "3",
                  "gasPrice": "20"
                },
                "senderRS": "Cx0-Q928-PG5A-8QH9-2WD92",
                "subtype": 1,
                "recipientRS": "Cx0-P92J-WQYH-JQ7W-D2CF6",
                "block": "10337575539167551223",
                "blockTimestamp": 227402366,
                "deadline": 2000,
                "timestamp": 227402274,
                "height": 1769438,
                "executedStatus": "executed",
                "senderPublicKey": "642b5671c1ab33a17d48794e97394c8c23de5744796a52d723aa4d3d61336d14",
                "confirmations": 14734,
                "fullHash": "fbb4b643a91300bff79ec5c0d63bea0f723b0df56f9dba2e1f808a7a2b36e79e",
                "version": 1,
                "sender": "8299850463124486",
                "recipient": "12841241267979066384",
                "ecBlockHeight": 1769428,
                "feeTQT": "33350000",
                "transaction": "13763022078950683899"
              }
            ]
          }

          callback({
            iTotalRecords: 1000,
            iTotalDisplayRecords: 1000,
            data: res,
          });
        });
      })
      .withDisplayLength(5)
      .withBootstrap();

    $scope.dtColumnsTransactions = [
      DTColumnBuilder.newColumn("transaction")
        .withTitle("Id")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          var details =
            '<a type="button" class="btn btn-infinity btn-xs" ng-controller="SearchCtrl"' +
            " ng-click=\"searchValue('" +
            data +
            "')\">" +
            '<i class="fa fa-list-ul" aria-hidden="true"></i>' +
            "</a>";
          return details;
        }),
      DTColumnBuilder.newColumn("type")
        .withTitle("Type")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return transactionIconSubTypeFilter(data, row.subtype);
        }),
      DTColumnBuilder.newColumn("timestamp")
        .withTitle("Date")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return $scope.formatDate(data);
        }),
      DTColumnBuilder.newColumn("amountTQT")
        .withTitle("Amount")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return numericalStringFilter(quantToAmountFilter(data));
        }),
      DTColumnBuilder.newColumn("feeTQT")
        .withTitle("Fee")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return numericalStringFilter(quantToAmountFilter(data));
        }),
      DTColumnBuilder.newColumn("confirmations")
        .withTitle("Conf.")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return getTransactionConf(data);
        }),
      DTColumnBuilder.newColumn("senderRS")
        .withTitle("Sender")
        .notSortable()
        .withOption("defaultContent", " ")
        .renderWith(function (data, type, row, meta) {
          if (data) {
            return searchTermFilter(data);
          }
          return data;
        }),
      DTColumnBuilder.newColumn("recipientRS")
        .withTitle("Recipient")
        .notSortable()
        .withOption("defaultContent", " ")
        .renderWith(function (data, type, row, meta) {
          if (data) {
            return searchTermFilter(data);
          }
          return data;
        }),
    ];

    function getTransactionConf(value) {
      if (value === 0) {
        return '<span class="label label-default">' + value + "</span>";
      } else if (value > 0 && value < 10) {
        return '<span class="label label-danger">' + value + "</span>";
      } else if (value >= 10 && value < 100) {
        return '<span class="label label-warning">' + value + "</span>";
      } else if (value >= 100 && value < 720) {
        return '<span class="label label-success">' + value + "</span>";
      } else {
        return '<span class="label label-success">+720</span>';
      }
    }

    $scope.dtInstanceCallbackTransactions = {};

    $scope.dtOptionsAssets = DTOptionsBuilder.newOptions()
      .withPaginationType("numbers")
      .withDOM("frtip")
      .withOption("serverSide", false)
      .withDataProp("accountAssets")
      .withOption("paging", true)
      .withOption("info", false)
      .withOption("ordering", false)
      .withOption("processing", false)
      .withOption("bFilter", false)
      .withOption(
        "fnRowCallback",
        function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          $compile(nRow)($scope);
        }
      )
      .withOption("ajax", function (data, callback, settings) {
        AccountService.getAccountAssets(params.accountId).then(function (
          response
        ) {
          callback({
            iTotalRecords: response.accountAssets.length,
            iTotalDisplayRecords: response.accountAssets.length,
            accountAssets: response.accountAssets,
          });
        });
      })
      .withDisplayLength(3)
      .withBootstrap();

    $scope.dtColumnsAssets = [
      DTColumnBuilder.newColumn("asset")
        .withTitle("Details")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          var tt_info =
            ' popover-placement="bottom" popover-trigger="\'mouseenter\'" uib-popover=' +
            ' "Assets Issuance details"';

          return (
            '<button type="button" class="btn btn-infinity btn-xs"  ' +
            tt_info +
            ' ng-controller="SearchCtrl"' +
            " ng-click=\"searchValue('" +
            data +
            "')\">" +
            '<i class="fa fa-list-ul" aria-hidden="true"></i>' +
            "</button>"
          );
        }),

      DTColumnBuilder.newColumn("name")
        .withTitle("Ticker")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return (
            '<a  class="pointer" style="width: 80%" ng-controller="AssetsCtrl" ng-click="openModal(\'' +
            row.asset +
            "')\">" +
            data.toUpperCase() +
            "</a>"
          );
        }),
      DTColumnBuilder.newColumn("quantityQNT")
        .withTitle("Holding")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return numericalStringFilter(
            quantityToShareFilter(data, row.decimals)
          );
        }),
      DTColumnBuilder.newColumn("numberOfAccounts")
        .withTitle("Shareholders")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return data;
        }),
      DTColumnBuilder.newColumn("numberOfTrades")
        .withTitle("Trades")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return data;
        }),
      DTColumnBuilder.newColumn("numberOfTransfers")
        .withTitle("Transfers")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return data;
        }),
      DTColumnBuilder.newColumn("asset")
        .withTitle("View")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          var tt_trades =
            ' popover-placement="bottom" popover-trigger="\'mouseenter\'" uib-popover=' +
            ' "Trades"';

          var tt_transfer =
            ' popover-placement="bottom" popover-trigger="\'mouseenter\'" uib-popover=' +
            ' "Transfers"';

          var trades =
            '<button type="button" class="btn btn-default btn-xs" ' +
            tt_trades +
            'ng-controller="AssetsCtrl" ng-click="openAssetTrades(\'' +
            data +
            "')\">" +
            '<i class="fa fa-bar-chart" aria-hidden="true" style="width:15px;"></i>' +
            "</button>";

          var transfers =
            '<button type="button" class="btn btn-default btn-xs" ' +
            tt_transfer +
            ' ng-controller="AssetsCtrl" ng-click="openAssetTransfers(\'' +
            data +
            "')\">" +
            '<i class="fa fa-user" aria-hidden="true" style="width:15px;"></i>' +
            "</button>";

          return trades + "&nbsp;" + transfers;
        }),
    ];

    $scope.dtInstanceCallbackAssets = {};

    $scope.dtOptionsCurrencies = DTOptionsBuilder.newOptions()
      .withPaginationType("numbers")
      .withDOM("frtip")
      .withOption("serverSide", false)
      .withDataProp("accountCurrencies")
      .withOption("paging", true)
      .withOption("info", false)
      .withOption("ordering", false)
      .withOption("processing", false)
      .withOption("bFilter", false)
      .withOption(
        "fnRowCallback",
        function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          $compile(nRow)($scope);
        }
      )
      .withOption("ajax", function (data, callback, settings) {
        AccountService.getAccountCurrencies(params.accountId).then(function (
          response
        ) {
          callback({
            iTotalRecords: response.accountCurrencies.length,
            iTotalDisplayRecords: response.accountCurrencies.length,
            accountCurrencies: response.accountCurrencies,
          });
        });
      })
      .withDisplayLength(3)
      .withBootstrap();

    $scope.dtColumnsCurrencies = [
      DTColumnBuilder.newColumn("currency")
        .withTitle("Details")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          var tt_info =
            ' popover-placement="bottom" popover-trigger="\'mouseenter\'" uib-popover=' +
            ' "Currency Issuance details"';

          return (
            '<button type="button" class="btn btn-infinity btn-xs"  ' +
            tt_info +
            ' ng-controller="SearchCtrl"' +
            " ng-click=\"searchValue('" +
            data +
            "')\">" +
            '<i class="fa fa-list-ul" aria-hidden="true" style="width:15px;"></i>' +
            "</button>"
          );
        }),

      DTColumnBuilder.newColumn("code")
        .withTitle("Code")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return (
            '<a  class="pointer" ng-controller="CurrenciesCtrl" ng-click="openModal(\'' +
            data.toUpperCase() +
            "')\">" +
            data +
            "</a>"
          );
        }),

      DTColumnBuilder.newColumn("name").withTitle("Name").notSortable(),

      DTColumnBuilder.newColumn("unconfirmedUnits")
        .withTitle("Supply")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return numericalStringFilter(
            quantityToShareFilter(data, row.decimals)
          );
        }),

      DTColumnBuilder.newColumn("numberOfExchanges")
        .withTitle("Exchanges")
        .notSortable(),

      DTColumnBuilder.newColumn("numberOfTransfers")
        .withTitle("Transfers")
        .notSortable(),

      DTColumnBuilder.newColumn("currency")
        .withTitle("View")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          var tt_trades =
            ' popover-placement="bottom" popover-trigger="\'mouseenter\'" uib-popover=' +
            ' "Exchanges"';

          var tt_transfer =
            ' popover-placement="bottom" popover-trigger="\'mouseenter\'" uib-popover=' +
            ' "Transfers"';

          var trades =
            '<button type="button" class="btn btn-default btn-xs" ' +
            tt_trades +
            ' ng-controller="CurrenciesCtrl" ng-click="openCurrencyExchanges(\'' +
            data +
            "','" +
            row.decimals +
            "')\">" +
            '<i class="fa fa-bar-chart" aria-hidden="true" style="width:15px;"></i>' +
            "</button>";

          var transfers =
            '<button type="button" class="btn btn-default btn-xs" ' +
            tt_transfer +
            ' ng-controller="CurrenciesCtrl" ng-click="openCurrencyTransfer(\'' +
            data +
            "','" +
            row.decimals +
            "')\">" +
            '<i class="fa fa-user" aria-hidden="true" style="width:15px;"></i>' +
            "</button>";

          return trades + "&nbsp;" + transfers;
        }),
    ];

    $scope.dtInstanceCallbackCurrencies = {};

    $scope.dtOptionsPolls = DTOptionsBuilder.newOptions()
      .withPaginationType("numbers")
      .withDOM("frtip")
      .withOption("serverSide", false)
      .withDataProp("polls")
      .withOption("paging", true)
      .withOption("info", false)
      .withOption("ordering", false)
      .withOption("processing", false)
      .withOption("bFilter", false)
      .withOption(
        "fnRowCallback",
        function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          $compile(nRow)($scope);
        }
      )
      .withOption("ajax", function (data, callback, settings) {
        AccountService.getAccountPolls(params.accountId).then(function (
          response
        ) {
          callback({
            iTotalRecords: response.polls.length,
            iTotalDisplayRecords: response.polls.length,
            polls: response.polls,
          });
        });
      })
      .withDisplayLength(3)
      .withBootstrap();

    $scope.dtColumnsPolls = [
      DTColumnBuilder.newColumn("offer")
        .withTitle("Details")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          var tt_info =
            ' popover-placement="bottom" popover-trigger="\'mouseenter\'" uib-popover=' +
            ' "Poll Details"';

          return (
            '<button type="button" class="btn btn-infinity btn-xs" style="" ng-controller="PollsCtrl" ng-click="openModal(\'' +
            row.poll +
            "')\">" +
            '<i class="fa fa-list-ul" aria-hidden="true"></i>' +
            "</button>"
          );
        }),

      DTColumnBuilder.newColumn("name")
        .withTitle("Name")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return data;
        }),

      DTColumnBuilder.newColumn("votingModel")
        .withTitle("Model")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return votingModelFilter(data);
        }),

      DTColumnBuilder.newColumn("options")
        .withTitle("Options")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return data.length;
        }),
      DTColumnBuilder.newColumn("finished")
        .withTitle("Finished")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return isEnabledFilter(data);
        }),

      DTColumnBuilder.newColumn("finishHeight")
        .withTitle("Height")
        .notSortable(),

      DTColumnBuilder.newColumn("poll")
        .withTitle("View")
        .notSortable()
        .renderWith(function (data, type, row, meta) {
          return (
            '<button type="button" class="btn btn-default btn-xs" ng-controller="PollsCtrl" ng-click="openPollData(\'' +
            data +
            "','" +
            row.name +
            "','" +
            row.description +
            '\')">  <i class="fa fa-bar-chart" aria-hidden="true"></i> </a>'
          );
        }),
    ];

    $scope.dtInstanceCallbackPolls = {};

    $scope.dtOptionsAliases = DTOptionsBuilder.newOptions()
      .withPaginationType("numbers")
      .withDOM("frtip")
      .withOption("serverSide", false)
      .withDataProp("aliases")
      .withOption("info", false)
      .withOption("paging", true)
      .withOption("ordering", false)
      .withOption("processing", false)
      .withOption("bFilter", false)
      .withOption(
        "fnRowCallback",
        function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          $compile(nRow)($scope);
        }
      )
      .withOption("ajax", function (data, callback, settings) {
        AccountService.getAccountAliases(params.accountId).then(function (
          response
        ) {
          callback({
            iTotalRecords: response.aliases.length,
            iTotalDisplayRecords: response.aliases.length,
            aliases: response.aliases,
          });
        });
      })
      .withDisplayLength(3)
      .withBootstrap();

    $scope.dtColumnsAliases = [
      DTColumnBuilder.newColumn("aliasName").withTitle("Name").notSortable(),
      DTColumnBuilder.newColumn("aliasURI").withTitle("URI").notSortable(),
    ];

    $scope.dtInstanceCallbackAliases = {};
  },
]);

angular.module("search").controller("TransactionSearchCtrl", [
  "$scope",
  "Restangular",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "$compile",
  "$uibModalInstance",
  "$q",
  "params",
  "searchTermFilter",
  "quantityToShareFilter",
  "numericalStringFilter",
  "quantToAmountFilter",
  "shareToQuantiyFilter",
  function (
    $scope,
    Restangular,
    DTOptionsBuilder,
    DTColumnBuilder,
    $compile,
    $uibModalInstance,
    $q,
    params,
    searchTermFilter,
    quantityToShareFilter,
    numericalStringFilter,
    quantToAmountFilter,
    shareToQuantiyFilter
  ) {
    $scope.cancel = function () {
      $uibModalInstance.dismiss("cancel");
    };

    $scope.showResult = function () {
      $scope.transaction = Restangular.stripRestangular(params.transaction);
    };

    $scope.convertCamelToRegular = function (text) {
      var result = text.replace(/([A-Z])/g, " $1");
      return result.charAt(0).toUpperCase() + result.slice(1);
    };

    $scope.generateSearchLink = function (searchTerm) {
      var accountHtml =
        '<a href="" ng-controller="SearchCtrl" ng-click="searchValue(\'' +
        searchTerm +
        "')\">" +
        searchTerm +
        "</a>";
      return accountHtml;
    };

    $scope.getTransactionConf = function (value) {
      if (value === 0) {
        return '<span class="label label-default">' + value + "</span>";
      } else if (value > 0 && value < 10) {
        return '<span class="label label-danger">' + value + "</span>";
      } else if (value >= 10 && value < 100) {
        return '<span class="label label-warning">' + value + "</span>";
      } else if (value >= 100 && value < 720) {
        return '<span class="label label-success">' + value + "</span>";
      } else {
        return '<span class="label label-primary">' + value + "</span>";
      }
    };

    $scope.getTransactionTypeGlyphicon = function (value) {
      switch (value) {
        case 0:
          return (
            '<span class="glyphicon ' +
            "glyphicon-usd" +
            ' " aria-hidden="true"></span>'
          );
        case 1:
          return (
            '<span class="glyphicon ' +
            "glyphicon-envelope" +
            ' " aria-hidden="true"></span>'
          );
        case 2:
          return (
            '<span class="glyphicon ' +
            "glyphicon-signal" +
            ' " aria-hidden="true"></span>'
          );
        case 3:
          return (
            '<span class="glyphicon ' +
            "glyphicon-shopping-cart" +
            ' " aria-hidden="true"></span>'
          );
        case 4:
          return (
            '<span class="glyphicon ' +
            "glyphicon-info-sign" +
            ' " aria-hidden="true"></span>'
          );
        case 5:
          return (
            '<span class="glyphicon ' +
            "glyphicon-random" +
            ' " aria-hidden="true"></span>'
          );
        case 6:
          return (
            '<span class="glyphicon ' +
            "glyphicon-save" +
            ' " aria-hidden="true"></span>'
          );
        case 7:
          return (
            '<span class="glyphicon ' +
            "glyphicon-link" +
            ' " aria-hidden="true"></span>'
          );
      }
    };
  },
]);

angular.module("search").controller("ErrorSearchCtrl", [
  "$scope",
  "$uibModalInstance",
  "$q",
  "params",
  function ($scope, $uibModalInstance, $q, params) {
    $scope.cancel = function () {
      $uibModalInstance.dismiss("cancel");
    };

    $scope.showResult = function () {
      $scope.message = params.message;
    };
  },
]);

angular.module("stats", [
  "baseBlockExplorer",
  "restangular",
  "datatables",
  "datatables.bootstrap",
  "ui.bootstrap",
  "ui.router",
]);

angular.module("stats").constant("statsConfig", {
  statsEndPoint: "api",
});

angular.module("stats").config([
  "RestangularProvider",
  "statsConfig",
  "$stateProvider",
  "$urlRouterProvider",
  "baseConfig",
  function (
    RestangularProvider,
    statsConfig,
    $stateProvider,
    $urlRouterProvider,
    baseConfig
  ) {
    RestangularProvider.setBaseUrl(baseConfig.apiUrl);

    $stateProvider.state("blockExplorer.stats", {
      url: "^/stats",
      templateUrl: "./stats/stats.html",
      controller: "StatsCtrl",
    });
  },
]);

angular.module("stats").service("StatsService", [
  "Restangular",
  "statsConfig",
  function (Restangular, statsConfig) {
    this.getStats = function () {
      var params = {
        requestType: "getStatistics",
      };
      return Restangular.all(statsConfig.statsEndPoint).customGET("", params);
    };

    this.getBalance = function (account) {
      var params = {
        requestType: "getBalance",
        account: account,
      };
      return Restangular.all(statsConfig.statsEndPoint).customGET("", params);
    };

    this.getTotalSupply = function () {
      return Restangular.oneUrl(
        "totalSupply",
        "http://supply.cpcoin.io/circulating-supply"
      ).get();
    };
  },
]);

angular.module("stats").controller("StatsCtrl", [
  "$scope",
  "StatsService",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "$interval",
  "$uibModal",
  "$compile",
  "baseConfig",
  function (
    $scope,
    StatsService,
    DTOptionsBuilder,
    DTColumnBuilder,
    $interval,
    $uibModal,
    $compile,
    baseConfig
  ) {
    $scope.initialSupply = baseConfig.initialSupply;
    $scope.circulatingSupply = "";

    $scope.stats = {};

    $scope.getStats = function () {
      StatsService.getStats().then(function (response) {
        $scope.stats = response;
      });

      // accountRS: Cx0-FNEJ-ZZPG-YRXW-CWHPY
      StatsService.getBalance("12634282505745650064").then(function (res) {
        var balance = parseInt(res.balanceTQT);
        $scope.circulatingSupply = (balance + 9865776752185763 - 490676840799999);
      });
    };
  },
]);

angular.module("blockExplorer", [
  "blocks",
  "assets",
  "currency",
  "poll",
  "unconfirmedTransactions",
  "transactions",
  "search",
  "accounts",
  "stats",
  "ui.router",
  "distributions",
]);

angular.module("blockExplorer").config([
  "$stateProvider",
  "$urlRouterProvider",
  function ($stateProvider, $urlRouterProvider) {
    $stateProvider.state("blockExplorer", {
      abstract: true,
      url: "/",
      template: "<div ui-view></div>",
    });

    $urlRouterProvider.otherwise("/blocks");

    document.title =
      "CPCx0 Chain - Block Explorer | " +
      window.getEnvConfig("NETWORK_ENVIRONMENT");
  },
]);

angular.module("blockExplorer").run([
  "$rootScope",
  "BASE_OPTIONS",
  "baseConfig",
  function ($rootScope, BASE_OPTIONS, baseConfig) {
    $rootScope.options = BASE_OPTIONS;

    $rootScope.formatDate = function (d) {
      if (!d) {
        return "n/a";
      }

      const inMilliseconds = (d + baseConfig.EPOCH) * 1000;
      const date = new Date(inMilliseconds);

      return (
        date.toLocaleDateString("en-US", { timeZone: "UTC" }) +
        " <strong>" +
        date.toLocaleTimeString("en-US", { timeZone: "UTC" }) +
        "</strong>" +
        " GMT"
      );
    };
  },
]);

angular.module("blockExplorer").controller("FooterController", [
  "$scope",
  "BlocksService",
  "baseConfig",
  function ($scope, BlocksService, baseConfig) {
    $scope.init = function () {
      $scope.connectedURL = baseConfig.apiUrl;
      BlocksService.getBlockChainStatus().then(function (success) {
        $scope.currentHeight = success.numberOfBlocks;
      });
      $scope.AssignedDate = Date;
    };
  },
]);
